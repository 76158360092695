import React, { useEffect, useState, useRef, useContext } from "react";

import { getParams } from "./Function";

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import { Helmet } from "react-helmet";


import logo from "./assets/logo.png";

import valerie from "./assets/valerie.png";

import presentation from "./assets/vitalsocks/presentation.png";
import carou1 from "./assets/vitalsocks/carou1.png";
import carou2 from "./assets/vitalsocks/carou2.png";
import carou3 from "./assets/vitalsocks/carou3.png";
import jambe from "./assets/vitalsocks/jambe.png";
import circulation from "./assets/vitalsocks/circulation.png";
import circulation2 from "./assets/vitalsocks/circulation2.png";
import schema from "./assets/vitalsocks/socks-schema.png";
import sockgif from "./assets/vitalsocks/socks-gif.gif";
import testedsocks from "./assets/vitalsocks/tested.png";

const PIXEL_ID = 1572135;

function VitalSocks() {
  let navigate = useNavigate();

  let location = useLocation();

  const { item, itemLoading, getItemData } = useContext(UserContext);

  useEffect(() => {
    getData();
    async function getData() {
      const itemId = getParams(location, "id");
      const itemBuf = await getItemData("vitalsocks");

      document.title = `${itemBuf.name} - Article révolutionnaire`;
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {` window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: ${PIXEL_ID}});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/${PIXEL_ID}/tfa.js',
  'tb_tfa_script');`}
        </script>
        <script>
          {` function taboolaPaymentDone(revenue, quantity) {
      _tfa.push({ notify: 'event', name: 'make_purchase', id:${PIXEL_ID}, 
      revenue, quantity, 
      currency: '€' })
    }`}
        </script>
      </Helmet>
      <header
        className="header new-element"
        style={{
          height: 170,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="new-element"
          style={{
            width: "100%",
            display: "flex",
            background: "rgb(20, 20, 20)",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "lightgray", fontSize: "1em" }}>
            Advertorial
          </span>
        </div>
        <img className="header-img" src={logo}></img>
      </header>
      {itemLoading == false && item && (
        <main className="container">
          <article className="container">
            <section className="home-blog-detail new-element">
              <div className="avatar-name-container">
                <img className="avatar-img" src={valerie}></img>
                <span style={{ fontStyle: "italic" }}>
                  Par Mélanie Faure, rédactrice Vita Bien-Être depuis 2016,{" "}
                  {item.date || "le 21 juin 2023"}
                </span>
              </div>
              <h1 className="home-title new-element">
                <b>
                  Éliminer vos douleurs plantaires, améliorer votre circulation
                  sanguine - Ces nouvelles chaussettes font des miracles
                </b>
              </h1>
              <p className="home-subtitle new-element">{item.subTitle}</p>
              <div className="new-element">
                <img className="full-img-norescale" src={presentation}></img>
                <span className="img-detail">
                  {item?.presentationImageDetail}
                </span>
              </div>

              <p className="blog-description-detail-smaller ">
                <b>
                  {" "}
                  Vos jambes sont lourdes, vous rencontrez des difficultés à
                  descendre les escaliers ou simplement à le poser en sortant du
                  lit chaque matin ? Certains mouvements sont pour vous un
                  véritable enfer, parce que vos articulations sont raides ou
                  douloureuses ? Arrêtez de vous sentir démunies face à vos
                  douleurs articulaires car il est maintenant possible d'apaiser
                  vos jambes en quelques minutes et à moindre prix !{" "}
                </b>
              </p>
              <p className="blog-detail-text">
                De nombreuses personnes voient la douleur s'installer et ne
                réagissent pas immédiatement. C'est une erreur ! Vous devez
                rapidement agir pour vous{" "}
                <b> débarrasser de cette douleur insupportable </b> et ainsi
                éviter toute aggravation,<b> prenez soin de votre Santé.</b>
              </p>
              <p className="blog-detail-text">
                <b> Le bas de notre corps est notre piliers </b>, nos pieds, nos
                jambes portent notre poids quotidiennement : ils nous{" "}
                <b>soutiennent</b> et nous <b> permettent de nous déplacer. </b>{" "}
                Assurent notre équilibre et amortissent les impacts.
              </p>
              <p className="blog-detail-text">
                C'est pourquoi il est important de{" "}
                <b>prendre soin de ses membres au quotidien </b> et de
                régulièrement consulter un Médecin, Podologue,{" "}
                <b> mais à quel prix ? </b>
              </p>
              <p className="blog-title">
                " VOICI LA SOLUTION QUI M'A SAUVÉ " Évoque Pierre
              </p>

              <img className="full-img-norescale" src={schema}></img>

              <p className="blog-detail-text">
                <b>
                  "Personne ne parvenait à soulager mes douleurs articulaires...{" "}
                </b>
                alors j'ai décidé de m’en occuper moi-même. J’ai toujours été
                une personne positive et heureuse. Mais les choses ont changé
                suite à une chirurgie de la phlébectomie due aux varices il y a
                environ deux ans.
              </p>

              <p className="blog-detail-text">
                Peu après, <b>l’arthrite </b>, qui affectait déjà mes pieds et
                ma cheville, a commencé <b> à s'attaquer à mes genoux... </b>{" "}
                Parfois, je ne pouvais soudainement plus marcher où me tenir
                debout... mes chevilles se dérobaient. La dernière fois que cela
                s'est produit, c'était lors d'une fête de famille, avec tous mes
                proches.
              </p>

              <p className="blog-detail-text">
                Subitement, <b> je ne pouvais plus marcher... </b> et la douleur
                était tout simplement atroce. Mon gendre a dû me retenir et je
                n'arrêtais pas de me demander :{" "}
                <b> Est-ce que ça va être ça, ma vie ? " </b>
              </p>

              <p className="blog-title">VIVEZ UNE VIE SANS DOULEUR</p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img className="blog-product-img-center" src={sockgif}></img>
              </div>

              <p className="blog-detail-text">
                " Peu de temps après, j'ai vu une{" "}
                <b>
                  {" "}
                  émission à la télévision sur les chaussettes de compression{" "}
                </b>{" "}
                sans coutures et j'ai commencé à faire des recherches de là je
                suis tombé sur le site VitalSocks. Après avoir lu{" "}
                <b> les commentaires des utilisateurs </b>, j'ai pensé que ça
                valait la peine d'essayer. J'ai commandé une paire de{" "}
                <b> VitalSocks </b> et j'ai commencé à les porter :{" "}
                <b>
                  {" "}
                  le matin en me levant, puis la nuit et quand j'ai constaté les
                  premiers résultats je les portais tout le temps.{" "}
                </b>
              </p>

              <p className="blog-detail-text">
                Bon, il faut être réaliste... à mon âge, on ne fait pas les
                choses aussi vite qu'avant. Mais avez-vous déjà vu{" "}
                <b>
                  {" "}
                  un homme de plus de 70 ans parcourir 10 à 15 kilomètres par
                  jour ?{" "}
                </b>{" "}
                Parce que c'est ce que je fais depuis le confinement de la
                COVID-19.{" "}
                <b>
                  {" "}
                  Mes articulations sont aussi beaucoup plus souples maintenant.{" "}
                </b>
              </p>

              <p className="blog-detail-text">
                Ce n’est vraiment pas pour me vanter... mais je me sens
                tellement <b> en forme et positif aujourd’hui ! VitalSocks </b>{" "}
                m'a rouvert des portes dont j'étais certain qu'elles étaient
                fermées pour toujours.
              </p>

              <p className="blog-detail-text">
                <b>
                  Croyez-moi... Si cela peut permettre à un vieil homme de 70
                  ans de se lever et de marcher sans douleur pendant plusieurs
                  heures chaque jour, imaginez ce que cela vous apportera à vous
                  !"
                </b>
              </p>

              <img
                className="full-img-norescale new-element"
                src={item.tableComparisonImage}
              ></img>

              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                Cliquez ici pour vérifier la disponibilité de {item.name}
              </button>

              <p className="blog-title">
                FACILITEZ VOTRE QUOTIDIEN EN ÉLIMINANT VOS DOULEURS
              </p>

              <img className="full-img-norescale" src={testedsocks}></img>

              <p className="blog-detail-text">
                {" "}
                Les <b> VitalSocks </b> sont des chaussettes qui utilisent la technologie
                des <b> zones de compression.</b> Avec des tissages spécialisés
                <b> d'élastiques solides </b> pour créer une pression sur les muscles des
                jambes, des chevilles et des pieds, <b>le soulagement de la douleur
                est presque instantané.</b>{" "}
              </p>

              <div className="blog-review-carousel new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    ✅ <b> ÉLIMINE LES DOULEURS </b>- Les Chausettes VitalSocks
                    éliminent la sensation de jambes lourdes et la fatigue. Elle
                    vous permettent de maintenir une vie active et agréable.
                    Obtenez la nuit de sommeil que vous méritez.
                  </p>
                </div>
                <img className="blog-review-carousel-img " src={jambe}></img>
              </div>

              <div className="blog-review-carousel new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    ✅ <b>AMÉLIORE LA CIRCULATION</b> - Elles sont fabriquées à
                    partir de fibres infusées de cuivre. Elles améliorent la
                    contraction musculaire et augmentent le flux sanguin des
                    jambes et des pieds. Cette technologie soulage la tension
                    qui s’accumule pendant la journée au niveau du mollet et de
                    la cheville.
                  </p>
                </div>
                <img
                  className="blog-review-carousel-img "
                  src={circulation}
                ></img>
              </div>

              <div className="blog-review-carousel new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    ✅ <b> RÉDUIT LES GONFLEMENTS </b> - En améliorant la
                    circulation, vous réduisez l’inflammation dans les pieds et
                    les chevilles. Ainsi, vous soulagez la douleur et les
                    gonflements
                  </p>
                </div>
                <img
                  className="blog-review-carousel-img "
                  src={circulation2}
                ></img>
              </div>

              <div className="orange-container">
                <p className="blog-detail-text">
                  Un traitement SANS MÉDICAMENTS qui fonctionne vraiment
                </p>
              </div>

              <p className="blog-detail-text">
                <b>
                  "VitalSocks est un produit incroyablement efficace que tout le
                  monde devrait utiliser au quotidien pour prendre soin de ses
                  pieds et permettre une bonne circulation sanguine." PS : En
                  plus, VitalSocks est protégé par une GARANTIE satisfait ou
                  remboursée de 30 jours. Il vous suffit simplement de leur
                  envoyer un mail et vous serez remboursé. MISE À JOUR :
                  VitalSocks est actuellement en promotion, vous pouvez le
                  commander à -50% et la livraison est GRATUITE
                </b>{" "}
              </p>

              <p className="blog-detail-text">
              <b> Où se procurer maintenant une paire de VitalSocks ?  </b> </p>
              <p className="blog-detail-text"> Vous pouvez le trouver directement sur le site officiel VitalSocks, en cliquant ici.
            
              </p>

              <p className="blog-detail-text">
              <b>PS </b>: En plus, VitalSocks est protégé par une <b> GARANTIE satisfait
                ou remboursée de 30 jours.</b> Il vous suffit simplement de leur
                envoyer un mail et vous serez remboursé.
              </p>

              <p className="blog-detail-text">
              <b>MISE À JOUR </b> : VitalSocks est actuellement en promotion, vous
                pouvez le commander à <b>-50%</b> et la <b>livraison est GRATUITE</b>
              </p>

              <img
                className="full-img-norescale new-element"
                src={item.tableComparisonImage}
              ></img>

              <button
                className="blog-button-clickhere new-element"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                Cliquez ici pour vérifier la disponibilité de {item.name}
              </button>

              <div className="orange-container">
                <p className="blog-detail-text">Témoignages de nos Clients</p>
                <p className="blog-detail-text">⭐️⭐️⭐️⭐️⭐️</p>
              </div>

              <p className="blog-title">
                Ils utilisent déjà {item.name}, voici ce qu'ils disent
              </p>

              <div className="blog-review-carousel new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    "Paires de chaussettes qui soutiennent super bien les
                    jambes. Surtout pour les personnes ayant des problèmes de
                    circulation sanguine dans les jambes. On ne les sent plus
                    une fois qu'on les porte. Confort agréable +++"
                  </p>
                  <p>Catherine K.</p>
                </div>
                <img className="blog-review-carousel-img " src={carou1}></img>
              </div>

              <div className="blog-review-carousel-reverse new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    "La matière est élastique et très agréable. L’efficacité et
                    le confort sont au rendez-vous pour les journées d’après
                    sport ou les journées de travail longtemps assis. Je pense
                    les utiliser également lors de mes prochains voyages en
                    avion. Très confortables pour le quotidien également pour
                    éviter les jambes lourdes surtout l’été approchant.Restent
                    intactes même après plusieurs lavages."
                  </p>
                  <p>Bruno F.</p>
                </div>
                <img className="blog-review-carousel-img " src={carou2}></img>
              </div>
              <div className="blog-review-carousel new-element">
                <div className="blog-review-carousel-text-container">
                  <p className="blog-review-carousel-text">
                    "J’ai testé les chaussettes pour la première fois pour
                    récupérer entre deux étapes du trail de montagne. Vraiment
                    efficace, pas de sensation de jambes lourdes ni de douleurs
                    aux mollets. Les meilleures que j’ai jamais eues jusqu’à
                    maintenant Bon produit , agréable à porter, confortables et
                    résistantes."
                  </p>
                  <p>Nathalie P.</p>
                </div>
                <img className="blog-review-carousel-img " src={carou3}></img>
              </div>

              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                Cliquez ici pour vérifier la disponibilité de {item.name}
              </button>

              <p
                className="blog-detail-text"
                style={{ display: "flex", textAlign: "center" }}
              >
                <b>
                  Commandez le vôtre maintenant avant qu'ils ne soient encore en
                  rupture de stock
                </b>
              </p>
            </section>
            <section className="sticky-item-container">
              <div
                className="sticky-item"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                <div className="sticky-title new-element">{item.name}</div>
                {item.description && (
                  <div className="sticky-subtitle new-element">
                    {item.description}
                  </div>
                )}
                <img
                  className="full-img-norescale new-element"
                  src={item.image}
                ></img>
                <span className="sticky-text">
                  Cliquez ici pour vérifier la disponibilité et le prix {">>"}
                </span>
              </div>
            </section>
          </article>
        </main>
      )}

      <footer className="footer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingInline: 10,
          }}
        >
          <img className="footer-img" src={logo}></img>
          <nav className="footer-nav">
            <span>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/mentions-legales"
              >
                Mentions légales -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/politique-de-confidentialite"
              >
                Politique de confidentialité -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/contact"
              >
                Contact
              </a>
            </span>
          </nav>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>À propos</p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ceci est un article sponsorisé et non un véritable article de news,
            ou blog. Cette histoire est basée sur les résultats que certaines
            personnes ont accomplis en utilisant ce produit. Les résultats
            peuvent varier en fonction des personnes et il se peut que vous
            n’atteignez pas le même résultat que sur les photos ou vidéos. Cette
            page peut recevoir une compensation pour chaque clic ou vente
            réalisée via ce site.
          </p>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>
            Clause de non-responsabilité en matière de Santé
          </p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ce site Web n'est pas destiné à fournir des conseils médicaux ou à
            se substituer aux conseils et traitements médicaux de votre médecin
            personnel. Les visiteurs sont invités à consulter leur propre
            médecin ou tout autre professionnel de la santé qualifié pour le
            traitement de leurs problèmes médicaux. L'auteur ne peut être tenu
            pour responsable d'une mauvaise compréhension ou d'une mauvaise
            utilisation des informations contenues sur ce site, ni d'une perte,
            d'un dommage ou d’une blessure causée, ou prétendument causée,
            directement ou indirectement par un traitement, une action ou une
            application d'un aliment ou d'une source alimentaire dont il est
            question sur ce site. Les informations ne sont pas destinées à
            diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.
          </p>
        </div>
      </footer>
    </>
  );
}

export default VitalSocks;
