import React, { useEffect, useState, useRef, useContext } from "react";

import { getParams } from "./Function";

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import { Helmet } from "react-helmet";

import logo from "./assets/logo.png";

import utilisation from './assets/purifiair/utilisation.png'
import howitworks from './assets/purifiair/howitworks.jpg'
import presentation2 from './assets/purifiair/presentation2_2.jpg'

import presentation from "./assets/suai/presentation.png";
import graphique from "./assets/suai/graphique.png";
import resultats from "./assets/suai/resultats.png";
import avis1 from "./assets/suai/avis1.png";
import avis2 from "./assets/suai/avis2.png";
import avis3 from "./assets/suai/avis3.png";
import avis4 from "./assets/suai/avis4.png";

const PIXEL_ID = 1571598;

function Suai() {
  let navigate = useNavigate();

  let location = useLocation();

  const { item, itemLoading, getItemData } = useContext(UserContext);

  useEffect(() => {
    getData();
    async function getData() {
      const itemId = getParams(location, "id");
      const itemBuf = await getItemData("suai");

      document.title = `${itemBuf.name} - Article révolutionnaire`;
    }
  }, []);

    return (
        <>
            <header className='header new-element' style={{ height: 170, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='new-element' style={{ width: '100%', display: 'flex', background: 'rgb(20, 20, 20)', justifyContent: 'center' }}>
                    <span style={{ color: 'lightgray', fontSize: '1em' }}>Advertorial</span>
                </div>
                <img className="header-img" src={logo}></img>
            </header>
            {itemLoading == false && item &&
                <main className='container'>

                    <article className='container'>

                        <section className="home-blog-detail new-element">
                            <div className='avatar-name-container'>
                                {/* <img className="avatar-img" src={valerie}></img> */}
                                <span style={{ fontStyle: 'italic' }}>Par Mélanie Faure, rédactrice Vita Bien-Être depuis 2016, {item.date || "le 21 juin 2023"}</span>
                            </div>
                            <h1 className='home-title new-element'>"La mycose des ongles infecte le sang et déclenche le processus de nécrose. Mais il y a une solution pour tout le monde."</h1>
                            <p className='home-subtitle new-element' >{item.subTitle}</p>
                            <div className='new-element'>
                                <img className="full-img-square" src={presentation}></img>
                                <span className='img-detail'>{item?.presentationImageDetail}</span>
                            </div>

              <p className="blog-description-detail-nobold ">
                Extrait de la conférence de presse de M Jean-Claude Dubois:{" "}
                <b>
                  "{item.enableCounter ? "La mycose" : "L'infection"} des ongles
                  pénètre dans le sang et les organes internes, déclenchant le
                  processus de septicémie et détruisant le corps."
                </b>
              </p>

              <p className="blog-description-detail-important">
                Interview exclusive avec M Jean-Claude Dubois.
              </p>

              <p className="blog-detail-text">
                <b>
                  Pourquoi pensez-vous que{" "}
                  {item.enableCounter ? "la mycose" : "l'infection"} des ongles
                  est une maladie dangereuse ?
                </b>
              </p>

              <p className="blog-detail-text">
                Savez-vous combien de personnes en 2022 ont été décédé à cause
                de {item.enableCounter ? "la mycose" : "l'infection"} ? Si on
                prend les statistiques de 2022 , 55 000 personnes sont décédées,
                dont plus de 31 000 à cause de{" "}
                {item.enableCounter ? "la mycose" : "l'infection"} des ongles,
                soit plus de la moitié.
              </p>

              <p className="blog-detail-text">
                {item.enableCounter ? "La mycose" : "L'infection"} des ongles
                n'agit pas seulement sur les ongles affectés. A travers les
                tissus du pied, elle pénètre dans le sang et se propage dans
                tout le corps
                {item.enableCounter &&
                  ", le champignon détruit littéralement le corps à l'intérieur, "}
                exerçant une pression colossale sur tous les organes. Ses spores
                pénètrent dans les organes internes et commencent à s'y
                multiplier progressivement, provoquant des dommages.{" "}
                {item.enableCounter ? "Une mycose" : "Une infection"} des
                organes internes se développe. Cela signifie que chaque personne
                infectée a les organes qui pourissent de l'intérieur. À tout
                moment, le cœur peut s'arrêter, un vaisseau cérébral peut se
                rompre, un cancer du foie ou une insuffisance rénale peut
                survenir.
              </p>

              <p className="blog-detail-text text-underline">
                <b>
                  Toute personne atteinte{" "}
                  {item.enableCounter ? "de mycose" : "d'infection"} des ongles
                  est en danger.
                </b>
              </p>
              <p className="blog-detail-text">
                C'est pourquoi je pense que{" "}
                <b>
                  {item.enableCounter ? "la mycose" : "l'infection"} des ongles
                  est une maladie extrêmement dangereuse.
                </b>{" "}
                Tout le monde connaît le cancer et l'oncologie, mais avouons-le,
                le cancer survient chez 3 à 4 % des personnes et{" "}
                {item.enableCounter ? "la mycose" : "l'infection"} des ongles
                apparaît presque chez toutes les personnes âgéés de 40-45 ans...
              </p>
              <p className="blog-detail-text">
                <b>
                  Pourquoi les médecins ne peuvent-ils pas faire face à cette
                  maladie ?
                </b>
              </p>

              <p className="blog-detail-text">
                Tout d'abord, les gens ne vont pas chez le médecin avec{" "}
                {item.enableCounter ? "une mycose" : "une infection"} des
                ongles, étant donné que ce n'est pas une maladie très
                dangereuse. Et s'ils y vont, seulement s'ils sentent un
                inconfort, par exemple s'ils ont des problèmes externes avec le
                pied ou les orteils. Les médecins donneront quelques conseils,
                ils se sentiront mieux et ensuite ils n'apparaîtront que
                lorsqu'ils seront complèment désespérés.
              </p>

              <p className="blog-detail-text">
                {item.enableCounter && "Mais pour éliminer le champignon, "}il
                est nécessaire de faire un traitement permanent. De plus, peu de
                gens comprennent les conséquences d'une infection {item.enableCounter && "fongique"} et ce
                qu'elle peut faire à vos organes internes. Je peux citer des
                centaines d’exemples, mais je me concentrerai sur les exemples
                qui démontrent le plus clairement le danger d'infection
                {item.enableCounter && " fongique"}.
              </p>

              <p className="blog-detail-text">
                D'une certaine manière, c’est bien de soigner sa peau de
                l'extérieur, mais cela ne vous éloignera pas de l'issue fatale.
              </p>

              <p className="blog-detail-text">
                Deuxièmement, il y a maintenant une pénurie monstrueuse de
                personnel dans les cliniques. Que faire s'il n'y a qu'un seul
                mycologue dans toute la ville et un déficit de dermatologues de
                plus de 37% ? Et parmi ceux qui sont présents, plus de la moitié
                viennent avec des diplômes achetés. C’est dangeureux de leur
                confier la santé des gens. Ils prescrivent parfois un tel
                traitement, qu'il vaudrait mieux que le patient reste à la
                maison et ne se rend pas à la clinique. D’autres spécialites ont
                terminé les universités avec mention mais n’ont pas assez de
                pratique, et je ne peux pas refuser d'embaucher de tels
                spécialistes. Si j’avais la possibilité, j'aimerais tous les
                licencier et embaucher du personnel qualifié, mais où puis-je
                les obtenir maintenant ?
              </p>

              <p className="blog-detail-text">
                C'est une pénurie aiguë de médicaments efficaces. Les pharmacies
                vendent de tout, sauf des produits qui peuvent vraiment aider.
                Les médicaments obsolètes qui n'aident plus ou les nouveaux
                contrefaçons, qui sont vantés partout, sont chers mais inutiles.
                De plus, les nouveaux médecins peuvent prescrire n'importe quoi.
                Il existe souvent de tels médicaments qui peuvent aider
                temporairement, mais qui en même temps affecteront tellement le
                foie ou l'estomac que les conséquences peuvent être extrêmement
                effrayantes. Et encore une chose, la plupart des médicaments ne
                visent pas le traitement complet{" "}
                {item.enableCounter && "du champignon"}, ils aident seulement à
                éliminer les sympômes externes{" "}
                {item.enableCounter && "du champignon"}, mais d’autres symptômes
                internes restent dans le corps et le tue lentement.
              </p>

              <p className="blog-detail-text">
                <b>Dans l’ensemble, on obtient ce résultat :</b> les gens ne
                reçoivent pas de traitement et meurent d'une septicémie ou d'une
                infection des organes internes causées par une simple{" "}
                {item.enableCounter ? "mycose" : "infection"} des ongles. Bien
                sûr, il existe des centres médicaux privés, mais à quel prix ?
                De plus, la seule tâche dans les centres médicaux privés est
                d’avoir le plus de gains que possible d’un patient. Il s'agit de
                leur objectif premier !
              </p>

              <p className="blog-detail-text">
                <b>
                  Que faire dans ce cas pour les personnes qui ont une{" "}
                  {item.enableCounter ? "mycose " : "infection "}
                  des ongles ?
                </b>
              </p>

              <p className="blog-detail-text">
                Le problème avec le personnel médical incompétant n'est pas si
                facile à résoudre. Ici, tout ne dépend pas de moi mais de
                l'activité des facultés de médecine. Je ne peux pas gérer la
                formation de nouveaux médecins. Par conséquent, nous ne devons
                pas nous attendre à une solution rapide à ce problème. De plus,
                cette situation n'est pas seulement dans notre pays, dans les
                pays voisins ce n'est pas mieux. La situation est grave presque
                partout.
              </p>

              <p className="blog-detail-text">
                Mais il y a aussi un moment joyeux dans cette lutte pour la vie
                des gens. Notre ville a été choisie comme une ville pilote qui a
                testé une préparation capable de guérir complètement{" "}
                {item.enableCounter ? "la mycose " : "l'infection "}
                des ongles et de l'éliminer du corps.
              </p>

              <p className="blog-detail-text">
                <b>
                  Pourquoi pensez-vous que cette préparation va en quelque sorte
                  changer la situation ?
                </b>
              </p>

              <p className="blog-detail-text">
                Elle guérit complètement{" "}
                {item.enableCounter ? "la mycose" : "l'infection"} des ongles,
                éliminant à la fois ses symptômes externes et nettoyant
                complètement le corps de ses traces. Dès les premiers jours
                après l'utilisation du produit, le danger pour la vie de la
                personne infectée, diminue presque à zéro. La personne commence
                simplement à utiliser cette préparation et déjà il est hors de
                la zone à risque. La préparation nettoie le sang et les organes
                internes {item.enableCounter && "du champignon"}, qui se propage
                dans tout le corps et arrête les processus causés{" "}
                {item.enableCounter && "par le champignon"} qui nous tue
                silencieusement de l'intérieur.
              </p>

              <p className="blog-detail-text">
                À mon avis, cette préparation sauvera des dizaines de milliers
                de vies. J'ai donc fait de mon mieux pour que notre ville soit
                choisi comme première ville pilote...
              </p>

              <p className="blog-detail-text">
                <b>
                  De quelle préparation s'agit-il ? Quel est le secret de son
                  efficacité?
                </b>
              </p>

              <p className="blog-detail-text">
                Cette préparation est actuellement un leader absolu dans le
                traitement des maladies {item.enableCounter && "fongiques"}, c'est un remède d'une
                nouvelle génération. Contrairement à de nombreuses autres
                préparations, elle n'est pas créée par une entreprise
                pharmaceutique commerciale mais par des structures scientifiques
                étatiques. Un groupe interdépartemental des meilleurs médecins
                et chercheurs de la France a été impliqué dans la création de la
                préparation. En termes d'efficacité, il est à quelques fois plus
                efficace que tous les traitements médicaux et cosmétiques
                traditionnels connus. En même temps, il n'a pas d'effets
                secondaires, ne provoque pas de dépendance et produit une
                immunité contre les agents pathogènes {item.enableCounter && "fongiques"}, ce qui permet
                d'exclure les cas de maladies répétées.
              </p>

              <p className="blog-detail-text">
                Le secret de son efficacité réside dans l'action combinée et
                efficace sur le corps humain. La seule raison de la survie de{" "}
                {item.enableCounter ? "la mycose" : "l'infection"} des ongles
                est la complexité de sa destruction entièrement. Le plus
                souvent, il reste quelques traces de{" "}
                {item.enableCounter ? "mycose " : "infection "}
                sur le pied ou les organes internes, et la maladie revient de
                nouveau. Dans ce cas, le système immunitaire du corps n'est pas
                capable de faire face seul.
              </p>

              <p className="blog-detail-text">
                SUAI forme des anticorps spéciaux qui agissent{" "}
                {item.enableCounter && "sur le champignon "}
                et le détruit dans tout le corps. De ce fait, le corps humain
                est complètement nettoyé {item.enableCounter && "du champignon"}
                , en plus il stimule la production d'anticorps à l'avenir. Un
                seul traitement avec SUAI permet de nettoier complètement le
                corps des infections {item.enableCounter && "fongiques"}, évitant ainsi toute
                complication.
              </p>

              <img className="blog-product-center-img" src={item.image}></img>

              <p className="blog-detail-text">
                <b>
                  Pourquoi SUAI est plus efficace que les autres médicaments
                  {item.enableCounter && " antifongiques"} connus et vendus en pharmacie?
                </b>
              </p>

              <p className="blog-detail-text">
                Les infections {item.enableCounter && "fongiques"} sont connues comme des organismes
                vivants et elles s'adaptent aux conditions environnementales
                comme tout organisme vivant. Absolument la plupart des agents
                {item.enableCounter && " antifongiques"} ont été développés il y a 20 à 30 ans et pendant
                cette période, les infections {item.enableCounter && "fongiques"} ont créé leur propre
                immunité contre la plupart des agents {item.enableCounter && "antifongiques"}. SUAI est un
                remède absolument nouveau, il a été développé en tenant compte
                de l'étude de la pathogenèse de plus de 6000 souches {item.enableCounter && "fongiques "}
                différentes et, grâce à la nouvelle formule unique, détruit
                complètement {item.enableCounter && "le champignon "}à la fois à
                l'extérieur - sur les ongles et la peau et à l'intérieur le
                corps et en nettoie le corps.
              </p>

              <p className="blog-detail-text">
                L'efficacité de la préparation est prouvée par des études menées
                par notre centre{" "}
                <b>
                  avec la participation de volontaires, en utilisant une variété
                  de remèdes populaires pour le traitement{" "}
                  {item.enableCounter && "des champignons"}, les résultats ont
                  confirmé la grande efficacité de SUAI :
                </b>
              </p>

              <img className="full-img-norescale " src={graphique}></img>

              <p className="blog-detail-text">
                SUAI neutralise complètement l'infection et décompose en même
                temps les particules {item.enableCounter && "fongiques"} dans le sang et les organes
                internes. Cela vous permet d'arrêter l'infection du corps et lui
                donne la possibilité de récupérer. Toutes les épidémies
                d'infection dans le corps sont éliminées. En conséquence, la
                personne est complètement nettoyée de l'infection {item.enableCounter && "fongique"} et
                revient à un état sain. De plus, après un traitement avec cette
                préparation, la personne développe une immunité{" "}
                {item.enableCounter && "contre le champignon"}, c'est-à-dire que
                le risque d'être à nouveau infecté est absent.
              </p>

              <p className="blog-detail-text">
                <b>Le remède a une action forte sur l'organisme :</b>
              </p>

              <p className="blog-detail-text">
                <b>1 - </b>Désinfecte et détruit la structure de l'infection
                {item.enableCounter && " fongique"};
              </p>

              <p className="blog-detail-text">
                <b>2 - </b>Élimine les démangeaisons, l'exfoliation et
                l'irritation de la peau des pieds après les premières
                applications;
              </p>

              <p className="blog-detail-text">
                <b>3 - </b>Restaure rapidement la structure des ongles et guérit
                les plaies et les fissures des pieds;
              </p>

              <p className="blog-detail-text">
                <b>4 - </b>Élimine les odeurs désagréables et normalise
                l'activité des glandes sudoripares;
              </p>

              <p className="blog-detail-text">
                <b>5 - </b>Empêche la reproduction et la croissance de la
                microflore pathogène;
              </p>

              <p className="blog-detail-text">
                <b>6 - </b>Normalise le système immunitaire, favorise la
                guérison des maladies {item.enableCounter && "fongiques"} chroniques;
              </p>

              <p className="blog-detail-text">
                <b>7 - </b>Nettoie le sang et la lymphe des produits de la
                putréfaction et de la décomposition{" "}
                {item.enableCounter && "des champignons"};
              </p>

              <p className="blog-detail-text">
                <b>8 - </b>Développe une immunité contre la plupart des souches
                connues de pathogènes {item.enableCounter && "fongiques"};
              </p>

              <p className="blog-detail-text">
                <b>9 - </b>Il a un emballage jetable innovant qui exclut la
                réinfection par {item.enableCounter && "des champignons"};
              </p>

              <p className="blog-detail-text">
                <b>10 - </b>Élimine complètement tous les symptômes de{" "}
                {item.enableCounter ? "la mycose " : "l'infection "}
                lors du traitement thérapeutique recommandé.
              </p>

              <img className="full-img-norescale " src={resultats}></img>

              <p className="blog-detail-text">
                SUAI est disponible sous forme de crème. La crème exclut la
                récurrence de l'infection {item.enableCounter && "fongique"} et la transmission de
                l'infection à une peau saine.
              </p>

              <p className="blog-detail-text">
                <b>
                  Y a-t-il des contre-indications ? Et pourquoi le médicament
                  est distribué avec une remise de 50% ?
                </b>
              </p>

              <p className="blog-detail-text">
                Il n'y a pas de contre-indications. La composition SUAI est
                choisie de manière à ne pas provoquer de réactions allergiques.
                La préparation peut être prise même si vous êtes allergique à
                d'autres médicaments. Elle est totalement sans danger pour le
                foie, qui souffre généralement beaucoup lors de la prise des
                médicaments.
              </p>

              <p className="blog-detail-text">
                Concernant la deuxième question comme je l'ai dit la préparation
                a été créée par les structures étatiques et le propriétaire de
                la formule de la préparation est l'État. Bien sûr, si le
                propriétaire de SUAI était une entreprise pharmaceutique, alors
                son prix serait beaucoup plus cher. Maintenant l'État a lancé ce
                programme pour encourager les citoyens à combattre la maladie
                avec SUAI. Le coût de production est partiellement payé avec le
                budget d’état, nous avons donc réussi à obtenir une quantité
                limitée de préparation pour la distribuer à la population avec
                une remise de 50%.
              </p>

              <p className="blog-detail-text">
                <b>
                  Comment les gens peuvent-ils obtenir SUAI ? Une ordonnance ou
                  un passeport est requis ?
                </b>
              </p>

              <p className="blog-detail-text">
                SUAI est livré directement depuis l'entrepôt. La livraison est
                gratuite. C’est le moyen le plus productif pour distribuer ce
                produit efficacement. Les patients atteints de{" "}
                {item.enableCounter ? "mycose" : "infection"} souffrent tous les
                jours et nous ne voulons pas les obliger à faire la queue et
                être soumis au stress ? Il est très simple d'obtenir la
                préparation – Il vous suffit simplement de le commander sur{" "}
                <b
                  className="hyperlink"
                  onClick={() => navigate("/commande/" + item.id)}
                >
                  le site officel SUAI
                </b>
              </p>

              <p className="blog-description-detail-important-nocenter">
                ATTENTION : LES STOCKS SONT LIMITÉS
              </p>

              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                CLIQUEZ ICI POUR VÉRIFIER LA DISPONIBILITÉ DE{" "}
                {item.name.toUpperCase()}
              </button>

              <p className="blog-detail-text">
                <b>OÙ SE PROCURER MAINTENANT SUAI ? </b>
                Vous pouvez le trouver directement sur{" "}
                <b
                  className="hyperlink"
                  onClick={() => navigate("/commande/" + item.id)}
                >
                  le site officiel SUAI, en cliquant ici.
                </b>
              </p>

              <p className="blog-detail-text">
                <b>
                  FAITES ATTENTION aux contrefaçons, car ils n'utilisent pas les
                  mêmes normes de sécurité que la préparation {item.name}{" "}
                  original et risquent fortement de ne pas fonctionner et d'être
                  potentiellement dangereux.
                </b>
              </p>

              <p className="blog-detail-text">
                <b>
                  {item.name} est livré avec une garantie de remboursement
                  valable 30 jours,
                </b>{" "}
                vous ne courez donc aucun risque en l'essayant !
              </p>

              <p className="blog-detail-text">
                <b>MISE À JOUR : SUAI</b> est actuellement en promotion, vous
                pouvez le commander à <b style={{ color: "green" }}>-50%</b> et{" "}
                <b style={{ color: "green" }}>la livraison est GRATUITE</b>
              </p>

              <button
                className="blog-button-clickhere new-element"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                JE VEUX PROFITER DE LA RÉDUCTION DE -50% MAINTENANT {">>"}
              </button>

              <img className="full-img-norescale" src={avis1}></img>
              <img className="full-img-norescale" src={avis2}></img>
              <img className="full-img-norescale" src={avis3}></img>
              <img className="full-img-norescale" src={avis4}></img>
            </section>
            <section className="sticky-item-container">
              <div
                className="sticky-item"
                onClick={() => {
                  navigate("/commande/" + item.id);
                }}
              >
                <div className="sticky-title new-element">{item.name}</div>
                {item.description && (
                  <div className="sticky-subtitle new-element">
                    {item.description}
                  </div>
                )}
                <img
                  className="full-img-norescale new-element"
                  src={item.image}
                ></img>
                <span className="sticky-text">
                  Cliquez ici pour vérifier la disponibilité et le prix {">>"}
                </span>
              </div>
            </section>
          </article>
        </main>
      }

      <footer className="footer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingInline: 10,
          }}
        >
          <img className="footer-img" src={logo}></img>
          <nav className="footer-nav">
            <span>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/mentions-legales"
              >
                Mentions légales -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/politique-de-confidentialite"
              >
                Politique de confidentialité -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/contact"
              >
                Contact
              </a>
            </span>
          </nav>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>À propos</p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ceci est un article sponsorisé et non un véritable article de news,
            ou blog. Cette histoire est basée sur les résultats que certaines
            personnes ont accomplis en utilisant ce produit. Les résultats
            peuvent varier en fonction des personnes et il se peut que vous
            n’atteignez pas le même résultat que sur les photos ou vidéos. Cette
            page peut recevoir une compensation pour chaque clic ou vente
            réalisée via ce site.
          </p>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>
            Clause de non-responsabilité en matière de Santé
          </p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ce site Web n'est pas destiné à fournir des conseils médicaux ou à
            se substituer aux conseils et traitements médicaux de votre médecin
            personnel. Les visiteurs sont invités à consulter leur propre
            médecin ou tout autre professionnel de la santé qualifié pour le
            traitement de leurs problèmes médicaux. L'auteur ne peut être tenu
            pour responsable d'une mauvaise compréhension ou d'une mauvaise
            utilisation des informations contenues sur ce site, ni d'une perte,
            d'un dommage ou d’une blessure causée, ou prétendument causée,
            directement ou indirectement par un traitement, une action ou une
            application d'un aliment ou d'une source alimentaire dont il est
            question sur ce site. Les informations ne sont pas destinées à
            diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.
          </p>
        </div>
      </footer>
    </>
  );
}

export default Suai;
