import React, { forwardRef, useImperativeHandle } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export const CheckoutForm = forwardRef((props, ref) => {
    const stripe = useStripe()
    const elements = useElements()

    useImperativeHandle(ref, () => ({

        async handleSubmit() {
      
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            })

           return {error, paymentMethod}
        },

        async handleRequireActions(clientSecret) {
      
            const { error, paymentIntent } = await stripe.handleCardAction(clientSecret)

           return {error, paymentIntent}
        }
    }));

    return (
    
        
       
         <CardElement className='full-stripe' options={{hidePostalCode: true}}></CardElement>
    
      
    )

})


