import React, { useEffect, useState, useRef, useContext } from 'react';

import { getParams } from './Function';

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import logo from './assets/logo.png'



function Contact() {

    let navigate = useNavigate();

    let location = useLocation();


    const { item, itemLoading, getItemData } = useContext(UserContext)

    useEffect(() => {

    }, [])


    return (
        <>
            <header className='header new-element' style={{ height: 170, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <img className="header-img" src={logo}></img>
            </header>

            <main className='container new-element' style={{ flexDirection: 'column', textAlign: 'center' }}>

                <h1>Contact</h1>
                <p>Si vous voulez contacter la rédaction</p>
                <p>contact@vita-bienetre.com</p>
            </main>



            <footer className='footer'>

            </footer>
        </>
    )
}

export default Contact