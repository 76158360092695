import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const TopScrolling = (props) => {
  const [previousPathname, setPreviousPathname] = useState("")
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname, previousPathname)
    setPreviousPathname(location.pathname)
    if(previousPathname !== location.pathname)
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

export default TopScrolling;