import './App.css';
import {
  Routes,
  Route,
  Outlet,
  useNavigate
} from "react-router-dom";

import TopScrolling from './TopScrolling';

import Home from './Home'

import TeethCare from './TeethCare'
import Purifiair from './Purifiair';
import VitalSocks from './VitalSocks';
import Suai from './Suai';
import UltraTech from './UltraTech';
import NightGlow from './NightGlow';

import Order from './Order'

import MentionsLegales from './MentionsLegales'

import Contact from './Contact'

import PolitiqueConfidentialite from './PolitiqueConfidentialite'



function App() {


  return (

    <TopScrolling>
  
      <Routes>
        <Route path="/" exact element={<Layout />}>
        
          <Route path="/article-revolutionnaire" element={<Home />} />
          <Route path="/teethcare-revolution-soin-des-dents" element={<TeethCare />} />
          <Route path="/vitalsocks-revolution-soin-des-pieds" element={<VitalSocks />} />
          <Route path="/purifiair-revolution-depollution-de-l-air" element={<Purifiair />} />
          <Route path="/nightglow-lunettes-nocturne" element={<NightGlow />} />
          <Route path="/suai-destructeur-de-mycose" element={<Suai />} />
          <Route path="/ulratechtv-chaines-gratuites" element={<UltraTech />} />
          <Route path="/commande/:id" element={<Order />} />
          <Route path="/politique-de-confidentialite" element={<PolitiqueConfidentialite />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
        
        </Route>
     
        <Route path="*" element={<><main>Page inexistante</main></>} />
      </Routes>
    </TopScrolling>

  );
}

function Layout() {
  return (
    <>
      {/* <SideBar /> */}
      <Outlet />
      {/* <Footer /> */}
    </>
  );
}

export default App;
