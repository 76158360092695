import React, { useEffect, useState, useRef, useContext } from "react";

import { getParams } from "./Function";

import { Helmet } from "react-helmet";

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import logo from "./assets/logo.png";

import thomas from "./assets/thomas.jpg";

import presentation from "./assets/nightglow/1705035709_1.jpg"
import howitworks from "./assets/nightglow/1705035719_2.jpg";
import presentation2 from "./assets/nightglow/1705035732_3.gif";
import presentation3 from "./assets/nightglow/1726718792_1705035801_4_compressed.gif";
import presentation5 from "./assets/nightglow/presentation5.jpg";
import presentation6 from "./assets/nightglow/presentation6.gif";
import presentation7 from "./assets/nightglow/presentation7.gif";
import presentation8 from "./assets/nightglow/presentation8.jpg";
import presentation9 from "./assets/nightglow/presentation9.jpg";
import presentation10 from "./assets/nightglow/presentation10.jpg";
import presentation11 from "./assets/nightglow/presentation11.jpg";
import presentation12 from "./assets/nightglow/presentation12.jpg";
import presentation13 from "./assets/nightglow/presentation13.jpg";




const PIXEL_ID = 1765971;

function NightGlow() {
  let navigate = useNavigate();

  let location = useLocation();

  const { item, itemLoading, getItemData } = useContext(UserContext);

  useEffect(() => {
    getData();
    async function getData() {
      const itemId = getParams(location, "id");
      const itemBuf = await getItemData("nightglow");

      document.title = `${itemBuf.name} - Article révolutionnaire`;
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {` window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: ${PIXEL_ID}});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/${PIXEL_ID}/tfa.js',
  'tb_tfa_script');`}
        </script>
        <script>
          {` function taboolaPaymentDone(revenue, quantity) {
      _tfa.push({ notify: 'event', name: 'make_purchase', id:${PIXEL_ID}, 
       revenue, quantity, 
      currency: '€' })
    }`}
        </script>
      </Helmet>
      <header
        className="header new-element"
        style={{
          // height: 170,
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div
          className=""
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "gray", fontSize: ".8em" }}>
            ADVERTORIAL
          </span>
        </div>
        {/* <img className="header-img" src={logo}></img> */}
      </header>
      {itemLoading == false && item && (
        <main className="container">
          <article className="container">
            <section className="home-blog-detail new-element">

              <h1 className="home-title new-element">
                Attention, conducteurs ! Ne mettez plus votre vie en danger en conduisant la nuit ! De nouvelles lunettes anti-éblouissement vous offrent une expérience de conduite plus sûre que jamais !
              </h1>
              <div className="avatar-name-container">
                <div className="avatar-img-container">
                  <img className="avatar-img-rounded" src={thomas}></img>
                </div>

                <div>
                  <span className="author-text" style={{ fontWeight: "bold", display: "flex", }}>
                    Thomas Papin

                  </span> <span style={{ fontWeight: "bold", display: "flex", }}>
                    {item.date || "21 septembre 2024"}
                  </span>
                </div>

              </div>
              <p className="home-subtitle new-element">{item.subTitle}</p>
              <div className="new-element">
                <img className="full-img-norescale" src={presentation}></img>

              </div>

              <p className="blog-detail-text ">
                Conduire la nuit n'est plus un problème avec ces lunettes anti-éblouissement à la pointe de la technologie !
              </p>

              <p className="blog-detail-text ">
                Plus de <b>40 000</b> personnes meurent chaque année dans des accidents de la route en EU, et plus de <b>60 %</b> de ces accidents mortels se produisent la nuit. Bien qu'il y ait moins de circulation après la tombée de la nuit, <b>le taux de mortalité dû aux accidents de nuit est trois fois plus élevé</b>. Fou, mais vrai !
              </p>

              <p className="blog-detail-text">
                Pourquoi cela ? <b>Veuillez lire attentivement les informations suivantes, cela peut sauver votre vie et celle de vos proches</b>.
              </p>

              <p className="blog-detail-text">
                La vision humaine diminue la nuit, surtout avec l'âge. La vision des conducteurs est floue dans une si faible lumière, et couplée à l'éblouissement des phares, il est plus difficile d'identifier les objets lointains.
              </p>

              <p className="blog-detail-text">
                Selon la recherche, même avec des phares à faisceau puissant, vous ne voyez qu'environ 500 pieds devant vous, donc il y a moins de temps pour réagir aux objets sur la route, surtout à des vitesses de conduite plus élevées.
              </p>

              <p className="blog-detail-text">
                Pour aggraver les choses, plusieurs géants de l'automobile se battent pour attirer des clients avec de nouveaux phares <b>LED sophistiqués</b>.
              </p>

              <img className="full-img-norescale" src={howitworks}></img>

              <p className="blog-detail-text">
                <b>Ces lumières aveuglent les autres conducteurs, provoquant des maux de tête et rendant très difficile la vision de la route et des personnes qui se déplacent</b>
              </p>

              <p className="blog-detail-text">
                Les conducteurs peuvent seulement ralentir immédiatement, mais ce ralentissement soudain peut entraîner des accidents graves lorsque d'autres voitures rapides ne peuvent pas s'arrêter à temps, provoquant finalement une tragédie.
              </p>
              <p className="blog-detail-text">
                Mais, il y a une bonne nouvelle !
              </p>
              <p className="blog-detail-text">
                <b>Récemment, une start-up de la Silicon Valley a lancé une technologie de lunettes innovante qui rend la conduite de nuit beaucoup plus sûre.</b>
              </p>
              <p className="blog-detail-text">
                <b>C'est presque comme avoir des "yeux de hibou" en conduisant.</b>

              </p>

              <p className="blog-detail-text">
                Vous pouvez voir ce qui se passe devant vous, avec une clarté cristalline, même dans le brouillard ou lorsque ces éblouissants phares LED sont allumés.
              </p>


              <p className="blog-title">Qu'est-ce que c'est ?</p>

              <img className="full-img-norescale" src={presentation2}></img>


              <p className="blog-detail-text">
                Ce sont les <strong>Lunettes NightGlow</strong>, <b>les premières lunettes de conduite nocturne au monde</b>, spécialement conçues pour contrer l'éblouissement des phares LED, offrant aux conducteurs une protection exceptionnelle pour leur sécurité de nuit.
              </p>

              <p className="blog-detail-text">
                Les lentilles <b>polarisées orange uniques</b>, équipées d'un revêtement anti-reflet spécial, réduisent efficacement l'éblouissement des phares puissants et des réverbères lumineux.
              </p>

              <p className="blog-detail-text">
                <b>Pourquoi ?</b>
              </p>

              <p className="blog-detail-text">
                Parce que <b>l'orange est complémentaire au bleu dans le spectre des couleurs</b>, la lumière bleue émise par les LED est réduite.
              </p>

              <p className="blog-detail-text">
                De plus, l'incorporation de l'orange dans la lumière blanche éclatante facilite l'intégration de l'éblouissement des phares orange en arrière-plan par rapport à la lumière blanche intense.
              </p>

              <p className="blog-detail-text">
                Les lentilles polarisées bloquent les ondes lumineuses horizontales des LED pour améliorer le contraste et éliminer l'éblouissement distrayant sans assombrir tout.
              </p>


              <img className="full-img-norescale" src={presentation3}></img>


              <p className="blog-detail-text">
                En outre, les <strong>Lunettes NightGlow</strong> améliorent la visibilité dans des conditions météorologiques difficiles comme le <b>brouillard, les jours nuageux et la pluie</b>, ainsi que dans des conditions de <b>faible luminosité en minimisant les reflets, en contrôlant la lumière dispersée et en réduisant l'éblouissement</b>.
              </p>

              <p className="blog-detail-text">
                Plus qu'un simple accessoire de mode, les <strong>Lunettes NightGlow</strong> sont un compagnon fiable pour la conduite de nuit.
              </p>

              <p className="blog-detail-text">
                C'est votre allié pour des trajets nocturnes plus sûrs, apportant confiance et sécurité que vous naviguiez dans les rues de la ville ou sur les routes de campagne vers votre destination.
              </p>

              <p className="blog-title">
                La technologie de cœur étonnante de "GlareCut"
              </p>

              <img className="full-img-norescale" src={presentation5}></img>


              <p className="blog-detail-text">
                L'équipe de recherche des <strong>Lunettes NightGlow</strong>, s'appuyant sur deux années de données de recherche de marché approfondies, a étudié les points de douleur des conducteurs.
              </p>


              <p className="blog-detail-text">
                Ils ont développé de <b>manière indépendante une technologie de pointe appelée "GlareCut"</b>, spécifiquement conçue pour la sécurité de conduite nocturne. <b>Cette technologie a déjà protégé plus de 300 000 conducteurs</b>.
              </p>

              <p className="blog-detail-text">
                <b>Cette technologie intègre des revêtements réfléchissants, des filtres dégradés et des techniques optiques avancées, visant à minimiser l'éblouissement nocturne et à améliorer le confort visuel des conducteurs.</b>
              </p>

              <p className="blog-title">
                💡 Technologie de revêtement réfléchissant
              </p>


              <p className="blog-detail-text">
                Utilisant une technologie de <b>film multicouche</b>, le revêtement réfléchissant dépose des matériaux à indice de réfraction élevé sur la surface des lentilles, contrôlant précisément la lumière incidente et minimisant les réflexions.
              </p>

              <p className="blog-detail-text">
                Cette technique avancée absorbe et supprime les sources lumineuses intenses comme les phares et les lampadaires, aidant à réduire l'éblouissement.
              </p>

              <img className="full-img-norescale" src={presentation6}></img>


              <p className="blog-title">
                💡 Design de filtrage dégradé
              </p>

              <p className="blog-detail-text">
                Basé sur un design optique dégradé, le filtrage dégradé introduit des couches de densité variable pour ajuster progressivement la transmission des différentes intensités lumineuses.
              </p>


              <p className="blog-detail-text">
                Cela réduit efficacement les intensités lumineuses du ciel et du sol, <b>offrant un plus grand confort aux conducteurs face à des niveaux de luminosité changeants.</b>
              </p>

              <p className="blog-title">
                💡 Technologie optique avancée
              </p>

              <p className="blog-detail-text">
                Employant une technologie optique avancée, le design de lentilles de précision et les revêtements anti-reflet éliminent l'éblouissement tout en maintenant la clarté et le réalisme de l'ensemble du champ de vision.
              </p>

              <p className="blog-detail-text">
                S'appuyant sur les principes de la réfraction et de la transmission, la courbure et la densité optique des lentilles sont ajustées avec précision, offrant des performances optiques optimales pendant la nuit.
              </p>

              <p className="blog-title">
                Pourquoi les Lunettes NightGlow rendent-elles fous des milliers de conducteurs ?
              </p>



              <img className="full-img-norescale" src={presentation7}></img>

              <p className="blog-title">
                💡 Design léger, confortable à porter
              </p>

              <p className="blog-detail-text">
                Les <strong>Lunettes NightGlow</strong> présentent un design léger, garantissant que le port des lunettes ne se ressent pas comme un poids. <b>Vous pouvez les porter en continu pendant 8 heures sans aucun inconfort !</b>
              </p>

              <p className="blog-detail-text">
                Les <b>plaquettes de nez intégrées s'adaptent confortablement au nez</b> sans exercer de pression sur le pont du nez.
              </p>

              <p className="blog-title">
                💡 Design enveloppant
              </p>

              <img className="full-img-norescale" src={presentation8}></img>

              <p className="blog-detail-text">
                Les <strong>Lunettes NightGlow</strong> se distinguent par leur style enveloppant exceptionnel. Cela minimise l'interférence de la lumière provenant de toutes les directions.
              </p>

              <p className="blog-detail-text">
                <b>La grande lentille frontale et les lentilles latérales améliorées offrent une couverture suffisante pour protéger efficacement les yeux et élargir le champ de vision.</b>
              </p>

              <p className="blog-title">
                💡 Design compatible avec les lunettes de prescription
              </p>

              <img className="full-img-norescale" src={presentation9}></img>

              <p className="blog-detail-text">
                Les <strong>Lunettes NightGlow</strong> sont <b>conçues pour les utilisateurs de lunettes de prescription</b>, s'associant facilement avec <b>des lunettes RX/lunettes myopes/lunettes presbytes, ce qui les rend plus pratiques</b>.
              </p>

              <p className="blog-detail-text">
                Elles conviennent également à ceux qui n'ont pas de lunettes de prescription, ce qui signifie qu'elles peuvent convenir à tout le monde.
              </p>


              <p className="blog-title">
                💡 Qualité et durabilité supérieures
              </p>

              <p className="blog-detail-text">
                Les <strong>Lunettes NightGlow</strong> sont dotées d'<b>une robuste monture en TR90</b>, un matériau durable reconnu pour sa résistance à l'usure exceptionnelle.
              </p>

              <p className="blog-detail-text">
                <b>Les lentilles, fabriquées en matériau TAC renforcé de 1,1 mm d'épaisseur</b>, offrent des propriétés anti-éclats, réduisant ainsi le risque de casse. <b>La structure de charnière en métal interne renforce la stabilité structurelle des lunettes</b>.
              </p>

              <p className="blog-detail-text">
                <b>Les performances de premier ordre des lunettes garantissent une longue durée de vie.</b>
              </p>

              <p className="blog-title">
                💡 Large éventail d'applications
              </p>

              <p className="blog-detail-text">
                En plus de <b>bloquer l'éblouissement nocturne</b>, les <strong>Lunettes NightGlow</strong> offrent une protection polyvalente. <b>Elles protègent contre la poussière et le vent, améliorant la visibilité dans des conditions de faible luminosité</b>.
              </p>


              <p className="blog-title">
                💡 Questions Fréquemment Posées :
              </p>

              <p className="blog-detail-text">
                <b>Q : Comment puis-je être sûr que ces lunettes de conduite nocturne me conviennent ?</b>
              </p>

              <p className="blog-detail-text">
                Rassurez-vous, ces lunettes de conduite nocturne sont conçues pour s'adapter à tout le monde, quelle que soit la taille ou la forme de votre visage. Bien qu'elles n'offrent pas le fit éclatant de vos lunettes RayBan, elles assurent une excellente couverture lors de la conduite de nuit, réduisant ainsi le risque d'accidents.
              </p>

              <p className="blog-detail-text">
                <b>Q : Puis-je porter ces lunettes pendant la journée ?</b>
              </p>


              <p className="blog-detail-text">
                Ces lunettes de vision nocturne sont conçues pour des conditions nocturnes, brumeuses, nuageuses et pluvieuses. Cependant, nous déconseillons de les utiliser en plein jour, car elles augmentent la luminosité, ce qui peut poser des risques en cas de lumière suffisante.
              </p>

              <p className="blog-title">
                Les gens en parlent avec enthousiasme :
              </p>

              <div className='blog-rating-container new-element'>
                <img className="blog-img-left new-margin" src={presentation10}></img>
                <p className='blog-detail-text'>Les lunettes s'ajustent facilement par-dessus mes lunettes de prescription, et elles sont très confortables à porter. Elles fonctionnent aussi bien que je l'espérais. L'éblouissement la nuit a été totalement réduit et je me sens beaucoup plus à l'aise lors de mes trajets nocturnes.
               <br/>- Catherine Faure</p>
              </div>
              <div className='blog-rating-container new-element'>
                <img className="blog-img-left new-margin" src={presentation11}></img>
                <p className='blog-detail-text'>J'adore mes lunettes de phare car elles aident mes yeux à se détendre pendant la conduite, surtout en hiver quand il pleut, qu'il neige, ou qu'il y a du verglas et différents types de temps. Elles rendent la conduite tellement plus facile en réduisant l'éblouissement. Je les recommande vivement !
               <br/>- Michel Tellier</p>
              </div>
              <div className='blog-rating-container new-element'>
                <img className="blog-img-left new-margin" src={presentation12}></img>
                <p className='blog-detail-text'>Ces lunettes font exactement ce qu'elles promettent. Elles éliminent complètement cet horrible éblouissement que l'on reçoit des autres voitures, que ce soit devant vous ou derrière. J'ai trouvé que conduire avec elles était beaucoup moins stressant et je n'avais plus à freiner inutilement à cause des phares des voitures.
               <br/>- Daniel Lopez</p>
              </div>

              <p className="blog-title">
              Quel est le prix ?
              </p>
              <img className="full-img-norescale" src={presentation13}></img>

              <p className="blog-detail-text">
              Les lunettes traditionnelles avec lentilles polarisées orange coûtent plus de 100€, donc vous vous attendriez à ce que les <strong>Lunettes NightGlow</strong> coûtent au moins cela...
              </p>

              <p className="blog-detail-text">
              Mais que diriez-vous si je vous disais que les <strong>Lunettes NightGlow</strong> se vendent à 79,99€, bien en dessous de vos attentes, n'est-ce pas ?
              </p>

              <p className="blog-detail-text">
              CEPENDANT, savez-vous ce qui est mieux que de payer un prix déjà plus que correcte ?
              </p>

              <p className="blog-detail-text">
              <b>Leur promotion à <strong>50 %</strong> où vous pouvez commander les vôtres pour seulement <strong>39,99€</strong> !</b>
              </p>

              <p className="blog-detail-text">
              C'est un petit prix à payer pour des lunettes anti-éblouissement high-tech et neuves, garantissant un voyage de conduite sûr et confortable pour vous et vos proches.
              </p>


              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  });
                }}
              >
                Cliquez ici pour vérifier la disponibilité des {item.name}
              </button>

              <p className="blog-title">
              Pourquoi sont-elles actuellement en promotion ?
              </p>

              <p className="blog-detail-text">
              Vous vous êtes déjà demandé qui paie pour ces annonces de marque luxueuses, ces magasins haut de gamme et ces grosses primes pour les dirigeants ? C'est ça, les amis – c'est nous, les clients...
              </p>

              <p className="blog-detail-text">
              Mais regardez, les <strong>Lunettes NightGlow</strong> ont choisi un chemin différent. Leur mission ? Offrir des lunettes anti-éblouissement de premier ordre à des milliers de conducteurs, garantissant des trajets sûrs et contribuant à réduire les accidents de la route.
              </p>

              <p className="blog-detail-text">
              Avec une remise de <strong>50 %</strong>, ils savaient qu'une fois que les gens auraient essayé leurs lunettes, les avis élogieux et le buzz viral sur les réseaux sociaux suivraient ! Rien ne vaut le pouvoir de clients satisfaits qui font passer le mot !
              </p>
              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  });
                }}
              >
                Cliquez ici pour vérifier la disponibilité des {item.name}
              </button>
          

              <p className="blog-detail-text">
              <b>Petite mise en garde : Lorsque la promotion prendra fin, les prix reviendront à la normale. Agissez maintenant !</b>
              </p>

              <p className="blog-title">
              Conclusion : Dois-je acheter maintenant ou attendre ?
              </p>

              <p className="blog-detail-text">
              Risquer votre vie chaque jour au volant ? Jouer à la roulette avec la mort ? S'il vous plaît, arrêtez cette folie !
              </p>

              <p className="blog-detail-text">
              <b>Vous devez valoriser votre vie !</b>
              </p>

              <p className="blog-detail-text">
              Il est temps de vous procurer les <strong>Lunettes NightGlow</strong>. Cette remise ne durera pas longtemps, et elles se vendent comme des petits pains. Saisissez l'opportunité !
              </p>

              <p className="blog-title">
              Où puis-je obtenir de véritables Lunettes NightGlow ?
              </p>

              <p className="blog-detail-text">
              <b>Pas de contrefaçons, le vrai est essentiel ! Ne vous retrouvez pas avec un ancien prototype ou une copie !</b>
              </p>

              <p className="blog-detail-text">
              Si elles sont encore en stock, procurez-vous vos <strong>Lunettes NightGlow</strong> sur <a onClick={() =>  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  })} >le site officiel ici</a>.
              </p>
              
               <p className="blog-detail-text blog-alert-container">
              <b>*Mise à jour :</b> L'entreprise propose actuellement une vente spéciale. Vous bénéficiez de <strong>50 %</strong> de réduction si vous les achetez aujourd'hui. <a onClick={() =>  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  })}>Vos <strong>Lunettes NightGlow</strong> sont disponibles uniquement sur leur site officiel ici >></a>
              </p>

          
              <button
                className="blog-button-clickhere"
                onClick={() => {
                  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  });
                }}
              >
                Cliquez ici pour vérifier la disponibilité des {item.name}
              </button>

          
             
            </section>
            <section className="sticky-item-container">
              <div
                className="sticky-item"
                onClick={() => {
                  navigate("/commande/" + item.id, {
                    state: { PIXEL_ID: PIXEL_ID },
                  });
                }}
              >
                <div className="sticky-title new-element">{item.name}</div>
                {item.description && (
                  <div className="sticky-subtitle new-element">
                    {item.description}
                  </div>
                )}
                <img
                  className="full-img-norescale new-element"
                  src={item.image}
                ></img>
                <button className="sticky-button">
                  <span className="sticky-text">
                    VÉRIFIEZ LA DISPONIBILITÉ →
                  </span>
                </button>

              </div>
            </section>
          </article>
        </main>
      )}

      <footer className="footer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingInline: 10,
          }}
        >
          <img className="footer-img" src={logo}></img>
          <nav className="footer-nav">
            <span>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/mentions-legales"
              >
                Mentions légales -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/politique-de-confidentialite"
              >
                Politique de confidentialité -{" "}
              </a>

              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/contact"
              >
                Contact
              </a>
            </span>
          </nav>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>À propos</p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ceci est un article sponsorisé et non un véritable article de news,
            ou blog. Cette histoire est basée sur les résultats que certaines
            personnes ont accomplis en utilisant ce produit. Les résultats
            peuvent varier en fonction des personnes et il se peut que vous
            n’atteignez pas le même résultat que sur les photos ou vidéos. Cette
            page peut recevoir une compensation pour chaque clic ou vente
            réalisée via ce site.
          </p>
        </div>

        <div style={{ maxWidth: 400, paddingInline: 10 }}>
          <p style={{ color: "white" }}>
            Clause de non-responsabilité en matière de Santé
          </p>
          <p style={{ fontSize: "0.8em", color: "lightgray" }}>
            Ce site Web n'est pas destiné à fournir des conseils médicaux ou à
            se substituer aux conseils et traitements médicaux de votre médecin
            personnel. Les visiteurs sont invités à consulter leur propre
            médecin ou tout autre professionnel de la santé qualifié pour le
            traitement de leurs problèmes médicaux. L'auteur ne peut être tenu
            pour responsable d'une mauvaise compréhension ou d'une mauvaise
            utilisation des informations contenues sur ce site, ni d'une perte,
            d'un dommage ou d’une blessure causée, ou prétendument causée,
            directement ou indirectement par un traitement, une action ou une
            application d'un aliment ou d'une source alimentaire dont il est
            question sur ce site. Les informations ne sont pas destinées à
            diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.
          </p>
        </div>
      </footer>
    </>
  );
}

export default NightGlow;
