import React, { useEffect, useState, useRef, useContext } from 'react';

import { getParams } from './Function';

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import logo from './assets/logo.png'

import valerie from './assets/valerie.png'

import presentation from './assets/suai/presentation.png'
import utilisation from './assets/purifiair/utilisation.png'
import howitworks from './assets/purifiair/howitworks.jpg'
import presentation2 from './assets/purifiair/presentation2_2.jpg'
import { Helmet } from 'react-helmet';

const PIXEL_ID = 1570222;

function UltraTech() {

    let navigate = useNavigate();

    let location = useLocation();


    const { item, itemLoading, getItemData } = useContext(UserContext)

    useEffect(() => {
        getData()
        async function getData() {
            const itemId = getParams(location, "id")
            const itemBuf = await getItemData('ultratechtv')

            document.title = `${itemBuf.name} - Article révolutionnaire`
        }

    }, [])




    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {` window._tfa = window._tfa || [];
  window._tfa.push({notify: 'event', name: 'page_view', id: ${PIXEL_ID}});
  !function (t, f, a, x) {
         if (!document.getElementById(x)) {
            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
         }
  }(document.createElement('script'),
  document.getElementsByTagName('script')[0],
  '//cdn.taboola.com/libtrc/unip/${PIXEL_ID}/tfa.js',
  'tb_tfa_script');`}
                </script>
                <script>
                    {` function taboolaPaymentDone(revenue, quantity) {
      _tfa.push({ notify: 'event', name: 'make_purchase', id:${PIXEL_ID}, 
       revenue, quantity, 
      currency: '€' })
    }`}
                </script>
            </Helmet>
            <header className='header new-element' style={{ height: 170, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='new-element' style={{ width: '100%', display: 'flex', background: 'rgb(20, 20, 20)', justifyContent: 'center' }}>
                    <span style={{ color: 'lightgray', fontSize: '1em' }}>Advertorial</span>
                </div>
                <img className="header-img" src={logo}></img>
            </header>
            {itemLoading == false && item &&
                <main className='container'>

                    <article className='container'>

                        <section className="home-blog-detail new-element">
                            <div className='avatar-name-container'>
                                <img className="avatar-img" src={valerie}></img>
                                <span style={{ fontStyle: 'italic' }}>Par Mélanie Faure, rédactrice Vita Bien-Être depuis 2016, {item.date || "le 21 juin 2023"}</span>
                            </div>
                            <h1 className='home-title new-element'>"La mycose des ongles infecte le sang et déclenche le processus de nécrose. Mais il y a une solution pour tout le monde."</h1>
                            <p className='home-subtitle new-element' >{item.subTitle}</p>
                            <div className='new-element'>
                                <img className="full-img-square" src={presentation2}></img>
                                <span className='img-detail'>{item?.presentationImageDetail}</span>
                            </div>

                            <p className='blog-description-detail ' >Vous en avez marre de tousser ou d’avoir mal à la tête régulièrement et vous voulez prendre soin de votre santé sans dépenser une fortune ? Dans ce cas, ne cherchez pas plus loin car nous avons trouvé LA solution qui permettra d’éliminer les bactéries présentes dans l’air de nos maisons responsables des maladies et des migraines.</p>

                            <p className='blog-description-subdetail'>Récemment, une startup allemande très prometteuse a mis au point un petit boîtier appelé PurifiAir qui permet de purifier l’air d’un lieu intérieur en 5 minutes. C’est-à-dire de totalement supprimer les particules nocives d’une maison, d’un bureau ou d’une voiture afin de respirer un air dépollué et rafraîchissant comme à l'hôpital.</p>

                            <p className='blog-title '>{item.whatIsTitle}</p>
                            <img className="blog-product-center-img" src={item.image}></img>


                            <p className='blog-detail-text'>
                                Voici PurifiAir, un boîtier qui permet de dépolluer l’air de sa maison afin d’y respirer un air plus sain.
                            </p>

                            <p className='blog-detail-text'>
                                Plusieurs études scientifiques poussées ont prouvé que l’air des nos maisons est 3 à 5 fois plus pollué que l’air extérieur, la société PurifiAir, qui a été créée par une startup allemande, s’était donnée pour objectif de rendre accessible à tous la purification de leur habitat, même aux foyers les plus modestes.
                            </p>

                            <p className='blog-detail-text'>
                                Pendant longtemps, ils ont essayé de concevoir un purificateur à fitre à moins de 100€. Mais toujours, ils se heurtaient aux prix de la technologie qui étaient irréductibles et ils ne sont pas parvenus à en concevoir un en dessous de 190€. Ce qui était déjà très bien par rapport aux purificateurs professionnels actuels qui se vendent plusieurs centaines d'euros et et pour certain au-delà de 600€, mais leur objectif n’était pas atteint et ils n’étaient pas satisfaits.
                            </p>
                            <p className='blog-detail-text'>
                                Mais aujourd'hui, ils ont réussi à créer un purificateur révolutionnaire à ozone  et à ion négatif nommé PurifiAir qui est capable de supprimer toutes les impuretés présentes dans l’air, comme les microparticules, le pollen, la poussière, les virus ou encore les mauvaises odeurs…
                            </p>
                            <p className='blog-detail-text'>
                                Un jour, pour contourner les problèmes de prix, l’un des ingénieurs de la startup eut l'idée de créer un appareil qui serait capable d’intégrer une nouvelle technologie qui permettrait d’éviter le changement de filtre répété et assurerait une efficacité totale pour un prix de fabrication moins élevé.
                            </p>
                            <p className='blog-detail-text'>
                                Le défi était de taille car jusqu'à maintenant personne n’était parvenu à faire cela. Mais après des mois de travail acharné, des centaines de prototypes réalisés et de nombreuses nuits blanches à se creuser la tête, ils y sont parvenus.
                            </p>
                            <p className='blog-detail-text'>
                                Ils ont mis au point PurifiAir, un appareil design et 100% fonctionnel capable de dépolluer l’air.
                            </p>



                            <p className='blog-title'>Comment fonctionne-t-il ?</p>

                            <img className="full-img-norescale" src={howitworks}></img>

                            <p className='blog-detail-text'><b>Il est très facile à mettre en place :</b></p>

                            <p className='blog-detail-text'>1 - Choisissez l’endroit idéal pour y purifier l’air : dans une pièce de votre maison, un placard, votre voiture, votre réfrigérateur etc…</p>
                            <p className='blog-detail-text'>2 - Optez pour deux solutions : utilisez votre PurifiAir sans fil avec une très grande autonomie ou branchez-le à l’aide du câble fourni</p>
                            <p className='blog-detail-text'>3 - Appuyez sur le bouton d’allumage situé sur la partie haute pour le mettre en fonction</p>
                            <p className='blog-detail-text'>4 - <b>Profitez d’un air sain et totalement purifié</b> en moins de 5 minutes</p>

                            <p className='blog-detail-text'>Vous pouvez alors respirer sans crainte dans tous vos lieux isolés et profiter d’une réelle protection contre les maladies et les odeurs désagréables.

                            </p>

                            <p className='blog-detail-text'>Le vrai avantage de PurifiAir est de pouvoir l’installer n'importe où grâce à sa batterie intégrée qui le rend 100% transportable. Il suffit simplement d’avoir une prise USB pour le recharger quand il n’y a plus de batterie ou de le laisser brancher si vous ne souhaitez pas changer son emplacement.
                            </p>

                            <p className='blog-detail-text'>De plus, contrairement aux autres purificateurs, vous n’avez rien d'autre à payer, PurifiAir n’a besoin d'aucun entretien ni de filtre de changement.
                            </p>

                            <p className='blog-detail-text'>Si vous souhaitez changer l’emplacement de votre appareil, il vous suffit simplement de le déplacer.
                            </p>



                            <p className='blog-title'>Que fait exactement l’appareil {item.name} ?</p>

                            <img className="full-img-square" src={presentation2}></img>



                            <p className='blog-detail-text'>
                                ✅ <b>Améliore la santé</b> - PurifiAir désinfecte l’air, capture la poussière et élimine les microparticules en créant un air purifié qui réduit grandement les risques de maladie et d'infection virale.
                            </p>
                            <p className='blog-detail-text'>
                                ✅  <b>Supprime les mauvaises odeurs</b> - Élimine les mauvaises odeurs dans une pièce de votre maison, votre dressing, votre réfrigérateur, ou encore dans votre voiture.
                            </p>
                            <p className='blog-detail-text'>
                                ✅  <b>Portable</b> - Utilisez PurifiAir n’importe où grâce à sa batterie intégrée et sa toute petite taille qui lui permet d’être entièrement transportable.
                            </p>
                            <p className='blog-detail-text'>
                                ✅  <b>Installation simple et rapide</b> - PurifiAir s’installe en moins de 5 minutes.
                            </p>
                            <p className='blog-detail-text'>
                                ✅  <b>Très peu encombrant</b> - PurifiAir est un appareil design et très discret qu’on ne remarque même pas dans la plupart des cas.
                            </p>
                            <p className='blog-detail-text'>
                                ✅  <b>Solution économique</b> - En comparaison, les autres purificateurs d'air peuvent devenir très coûteux, PurifiAir est un choix avantageux. Grâce à sa toute nouvelle technologie brevetée à ozone, il n’y a pas besoin d’acheter des filtres de rechange.
                            </p>




                            <p className='blog-title'>Quel est son prix ?</p>


                            <p className='blog-detail-text'><b>Par rapport à l’achat d’un filtre à air classique, la solution PurifiAir est nettement plus économique.</b> Sans parler du fait qu'il possède de nombreux autres avantages comme le fait qu’il n’ait pas besoin d’entretien grâce à sa toute nouvelle technologie d’ozone à ion négatif ou encore qu'il ne consomme que très peu d'électricité.</p>

                            <p className='blog-detail-text'>Concernant son prix, PurifiAir est exceptionnellement disponible à moitié prix pour sa sortie commerciale en France en ce moment même.
                            </p>

                            <p className='blog-detail-text'>Au lieu d’être vendu à 99€, PurifiAir est donc disponible cette semaine pour 49€ seulement (l’entreprise a déjà publié un communiqué comme quoi il n’y aura plus jamais de promotion aussi importante que celle-ci).
                            </p>

                            <img className="full-img-norescale new-element" src={item.tableComparisonImage}></img>



                            <button className='blog-button-clickhere' onClick={() => { navigate("/commande/" + item.id) }}>Cliquez ici pour vérifier la disponibilité de {item.name}</button>

                            <p className='blog-title'>Ils utilisent déjà {item.name}, voici ce qu'ils disent</p>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"Depuis très longtemps, je rêvais d’une vie sans pollen car je n’en pouvais plus de pleurer en permanence et d’éternuer mais je n’avais pas de quoi me payer un filtre à particule. Maintenant avec PurifiAir, c’est tout comme si j’avais un appareil professionnel mais pour un quart du prix, je suis vraiment très satisfait de cet appareil."</p>
                                <p className='blog-review-user'>- Jean, 33 ans</p>
                            </div>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"J’ai acheté PurifiAir sur la recommandation des spécialistes qui ont démontré sur un reportage scientifique l’importance d’avoir un filtre à air dans sa maison et je dois dire que je ne suis pas du tout déçu. En plus hier mes enfants mon appris que l’air dans une maison est 4 fois plus pollué que l’air extérieur, c’est terrifiant."</p>
                                <p className='blog-review-user'>- Sandrine, 51 ans</p>
                            </div>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"Je ne peux plus m’en passer, chacune des mes pièces en est équipée et maintenant je n’ai presque plus aucune poussière dans ma maison, ma petite astuce c'est d’en mettre un dans le frigo pour enlever toutes les odeurs de fromages et des restes de la veille. Pour le prix je trouve que cet appareil est vraiment une petite merveille."</p>
                                <p className='blog-review-user'>- Josephine, 58 ans</p>
                            </div>

                            {item.conclusionText &&
                                <>
                                    <p className='blog-title'>Conclusion : Est-ce une bonne idée d’utiliser l’appareil {item.name} ?</p>



                                    <p className='blog-detail-text'><b>100% Oui.</b> Si vous souhaitez respirer un air plus sain nous vous le conseillons fortement. Chaque jour, l’air que nous respirons devient de plus en plus néfaste et peut causer de graves problèmes de santé.</p>
                                    <p className='blog-detail-text'>La cerise sur le gâteau : vous n'avez même pas besoin d’acheter de filtre de changement car il utilise une toute nouvelle technologie ozone, qui stérilise l’air, agréée par les services sanitaires.</p>

                                </>}


                            <p className='blog-title'>Comment faire pour obtenir un {item.name}</p>

                            <p className='blog-detail-text'><b>FAITES ATTENTION aux contrefaçons, car ils n'utilisent pas les mêmes normes de sécurité que l’appareil {item.name} original et risquent fortement de ne pas fonctionner.</b></p>

                            <p className='blog-detail-text'><b>{item.name} est livré avec une garantie de remboursement valable 30 jours,</b> vous ne courez donc aucun risque en l'essayant ! Si vous agissez maintenant, vous pouvez encore bénéficier de leur remise exceptionnelle de 50% valable uniquement jusqu’à la fin de semaine. N'attendez donc pas pour pouvoir profiter de {item.name} à prix cassé !</p>

                            <button className='blog-button-clickhere' onClick={() => { navigate("/commande/" + item.id) }}>Cliquez ici pour vérifier la disponibilité de {item.name}</button>

                            <p className='blog-detail-text' style={{ display: 'flex', textAlign: 'center' }}><b>Commandez le vôtre maintenant avant qu'ils ne soient encore en rupture de stock</b></p>
                        </section>
                        <section className='sticky-item-container'>
                            <div className='sticky-item' onClick={() => { navigate("/commande/" + item.id) }}>
                                <div className='sticky-title new-element'>
                                    {item.name}
                                </div>
                                {item.description &&
                                    <div className='sticky-subtitle new-element'>
                                        {item.description}
                                    </div>
                                }
                                <img className="full-img-norescale new-element" src={item.image}></img>
                                <span className='sticky-text'>
                                    Cliquez ici pour vérifier la disponibilité et le prix {'>>'}
                                </span>
                            </div>
                        </section>
                    </article>

                </main>
            }


            <footer className='footer'>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingInline: 10 }}>
                    <img className="footer-img" src={logo}></img>
                    <nav className='footer-nav'>
                        <span>
                            <a style={{ color: "white", textDecoration: 'none' }} href='/mentions-legales'>Mentions légales - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/politique-de-confidentialite'>Politique de confidentialité - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/contact'>Contact</a>
                        </span>

                    </nav>
                </div>


                <div style={{ maxWidth: 400, paddingInline: 10 }}>
                    <p style={{ color: 'white' }}>À propos</p>
                    <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ceci est un article sponsorisé et non un véritable article de news, ou blog. Cette histoire est basée sur les résultats que certaines personnes ont accomplis en utilisant ce produit. Les résultats peuvent varier en fonction des personnes et il se peut que vous n’atteignez pas le même résultat que sur les photos ou vidéos. Cette page peut recevoir une compensation pour chaque clic ou vente réalisée via ce site.</p>
                </div>

                <div style={{ maxWidth: 400, paddingInline: 10 }}>
                    <p style={{ color: 'white' }}>Clause de non-responsabilité en matière de Santé</p>
                    <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ce site Web n'est pas destiné à fournir des conseils médicaux ou à se substituer aux conseils et traitements médicaux de votre médecin personnel. Les visiteurs sont invités à consulter leur propre médecin ou tout autre professionnel de la santé qualifié pour le traitement de leurs problèmes médicaux. L'auteur ne peut être tenu pour responsable d'une mauvaise compréhension ou d'une mauvaise utilisation des informations contenues sur ce site, ni d'une perte, d'un dommage ou d’une blessure causée, ou prétendument causée, directement ou indirectement par un traitement, une action ou une application d'un aliment ou d'une source alimentaire dont il est question sur ce site. Les informations ne sont pas destinées à diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.</p>

                </div>


            </footer>
        </>
    )
}

export default UltraTech