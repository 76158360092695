import React, { useEffect, useState, useRef, useContext } from 'react';

import { getParams } from './Function';

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import logo from './assets/logo.png'

import valerie from './assets/valerie.png'

import rating from './assets/rating.png'



function TeethCare() {

    let navigate = useNavigate();

    let location = useLocation();


    const { item, itemLoading, getItemData } = useContext(UserContext)

    useEffect(() => {
        getData()
        async function getData() {

            const itemBuf = await getItemData("teethcare")

            document.title = `${itemBuf.name} - Article révolutionnaire`
        }

    }, [])

    console.log(item)


    return (
        <>
            <header className='header new-element' style={{ height: 170, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='new-element' style={{ width: '100%', display: 'flex', background: 'rgb(20, 20, 20)', justifyContent: 'center' }}>
                    <span style={{ color: 'lightgray', fontSize: '1em' }}>Advertorial</span>
                </div>
                <img className="header-img" src={logo}></img>
            </header>
            {itemLoading == false && item &&
                <main className='container'>



                    <article className='home-container'>

                        <section className="home-blog-detail new-element">
                            <div className='avatar-name-container'>
                                <img className="avatar-img" src={valerie}></img>
                                <span style={{ fontStyle: 'italic' }}>Par Mélanie Faure, rédactrice Vita Bien-Être depuis 2016, {item.date || "le 19 août 2022"}</span>
                            </div>
                            <h1 className='home-title new-element'>{item.title}</h1>
                            <p className='home-subtitle new-element' >{item.subTitle}</p>
                            <div className='new-element'>
                                <img className="full-img" src={item.presentationImage}></img>
                                <span className='img-detail'>{item.presentationImageDetail}</span>
                            </div>

                            {item.resumeText &&
                                <div className='home-resume new-element'>
                                    <span className='blog-detail-text' dangerouslySetInnerHTML={{ __html: item.resumeText }} />
                                </div>
                            }

                            <p className='blog-description-detail ' >{item.presentationDescriptionTitle}</p>

                            <p className='blog-description-subdetail' >{item.presentationDescriptionSubTitle}</p>




                            <p className='blog-title'>Elle Nettoie Vraiment Toutes Les Dents en Même Temps ?</p>

                            <img className="full-img-norescale" src={item.imageArr[0]}></img>

                            <p className='blog-detail-text'>On l’appelle <strong style={{ color: 'orange' }}>TeethCare</strong>, la dernière invention à venir d’une start-up basée dans la petite nation européenne du Liechtenstein, premier producteur mondial de matériel dentaire de haute qualité.</p>

                            <p className='blog-detail-text'>C’est parti d’une idée simple, à savoir comment rendre le nettoyage des dents aussi rapide et indolore que possible, afin que même les personnes les plus paresseuses s’y attèlent ?</p>

                            <p className='blog-detail-text'>La solution est la TeethCare 360, <b>un embout buccal vibrant qui vibre 5000 fois par minute, permettant un nettoyage en profondeur et efficace</b> sans aucun poil ni fil ne blessant vos gencives. La lumière bleue spéciale diffusée finalise le blanchiment des dents et élimine toute tache de café ou de tabac.</p>

                            <p className='blog-detail-text'>Et le mieux ? <b>Il faut moins d’une minute pour nettoyer TOUTES vos dents à la fois.</b></p>

                            <p className='blog-detail-text'>Tout ce que vous avez à faire est de suivre ces deux étapes toutes simples :</p>

                            <p className='blog-detail-text'>1. Appliquez du dentifrice tout autour de votre TeethCare</p>

                            <img className="full-img-norescale" src={item.imageArr[1]}></img>

                            <p className='blog-detail-text'>2. Insérez-la dans votre bouche et appuyez sur le bouton d’alimentation. En moins d’une minute, vos dents seront propres et luisantes.</p>

                            <img className="full-img-norescale" src={item.imageArr[2]}></img>

                            <p className='blog-title'>Est-ce que ce système est réel ?</p>

                            <img className="full-img-norescale" src={item.imageArr[3]}></img>

                            <p className='blog-detail-text'>Nous allons être honnêtes, quand nous avons entendu parler du fonctionnement de cet appareil, nous avons craint que toutes ces vibrations soient inconfortables ou fassent vibrer toutes nos dents, alors nous avons tiré à la courte paille – en nous assurant bien évidemment que l’un des jeunes membres de l’équipe se retrouve à endosser le rôle de cobaye.</p>

                            <p className='blog-detail-text'>À notre grande surprise, le sujet du test n’était non seulement pas du tout incommodé, mais il semblait même grandement apprécier l’appareil, le laissant pendant une minute entière, soit le double du temps nécessaire. Nous l’avons laissé ramener l’appareil à la maison, après lui avait fait promettre de l’utiliser suivant le mode d’emploi afin d’avoir les résultats les plus objectifs.</p>

                            <p className='blog-detail-text'>Nous lui avons également demandé d’utiliser la <b>fonction de blanchiment à la lumière bleue</b> (qui vous oblige à la garder dans votre bouche pendant 5 minutes afin d’obtenir de meilleurs résultats), car c’est l’un des plus grands accros au café du bureau.</p>

                            <p className='blog-title'>Pouvoir blanchissant et protecteur qui fournit des résultats vraiment professionnels</p>

                            <p className='blog-detail-text'>Au bout de deux mois, les résultats dont nous étions témoins au quotidien étaient stupéfiants, et nous l’avons fait asseoir pour déterminer en quoi consistait <strong style={{ color: 'orange' }}>TeethCare</strong> :</p>

                            <p className='blog-detail-text'>✅ <b>Nettoyage révolutionnaire de 5000 vibrations</b> par minute avec modes changeables pour un nettoyage en profondeur ou pour les dents sensibles.</p>

                            <p className='blog-detail-text'>✅ <b>Embout buccal confortable</b> pour s’adapter à n’importe quelle bouche.</p>

                            <p className='blog-detail-text'>✅ <b>Super facile et confortable à utiliser</b>, il suffit de la placer dans votre bouche et d’appuyer sur un bouton, laissez-la en place durant 30 secondes.</p>

                            <p className='blog-detail-text'>✅ <b>Un nettoyage en profondeur aide</b> à prévenir des conditions comme la gingivite.</p>

                            <p className='blog-detail-text'>✅ <b>Luminothérapie avancée</b> pour le blanchiment des dents.</p>

                            <p className='blog-detail-text'>✅ <b>Longue durée de vie de la batterie</b>, une charge est suffisante pour un mois d’utilisation maximum.</p>

                            <p className='blog-detail-text'>✅ <b>Gain de temps !</b> Seulement 30 secondes pour des dents propres !</p>

                            <img className="full-img-norescale" src={item.imageArr[4]}></img>

                            <p className='blog-detail-text'>Le temps prédéfini pour ce qui est considéré comme un bon nettoyage des dents est de brosser CHAQUE côté de CHAQUE dent individuelle pendant 30 secondes ! Étant donné que nous sommes tous dotés de 32 dents, il est facile de comprendre pourquoi presque personne ne se brosse les dents suffisamment longtemps pour qu’elles soient considérées comme vraiment propre. C’est là que <strong style={{ color: 'orange' }}>TeethCare</strong> entre en jeu. Celle-ci <b>permet un brossage des dents de tous les côtés à la fois, pour toutes les dents à la fois,</b> <b>rendant ainsi vos dents propres en moins d’une minute.</b> Sérieusement, comment cette invention sort-elle seulement maintenant ?!</p>

                            <p className='blog-detail-text'>Enfin, nous arrivons au prix. Un équipement dentaire de qualité supérieure ne peut pas être bon marché, n’est-ce pas ? Figurez-vous que le prix initial de TeethCare n’est que de <strong style={{ color: 'red' }}>118,00€</strong> mais la société organise actuellement une vente de lancement, offrant l’appareil avec une réduction de 50% et une livraison gratuite dans le monde entier! (Elle est disponible à présent à <strong style={{ color: 'green' }}>seulement 59€</strong>)</p>

                            <p className='blog-detail-text'>Compte tenu du fait que vous n’avez pas besoin de la jeter tous les trois mois comme une brosse standard, et que vous ferez de sérieuses économies en vous épargnant de futures visites chez le dentiste, c'est un prix incroyable.</p>

                            <p className='blog-title'>98% Des Utilisateurs Recommandent La TeethCare À Leurs Amis</p>

                            <p className='blog-detail-text'>En moins de 3 minutes, nettoyez toutes vos dents !</p>

                            <img className="full-img-norescale" src={item.imageArr[5]}></img>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"J'ai dépensé beaucoup d'argent et beaucoup de temps à souffrir, récemment, parce que j'ai négligé mes dents pendant trop longtemps. Je n’arrivais pas à prendre l’habitude de me brosser les dents deux fois par jour et j'avais peur de devoir retourner chez le dentiste tôt ou tard. C’est là que je suis tombée sur cet appareil incroyable et, grâce à lui, maintenant je garde toujours mes dents propres d’une manière on ne peut plus facile ! "</p>
                                <p className='blog-review-user'>- Maeva P. de Dijon</p>
                            </div>

                            <img className="full-img-norescale" src={item.imageArr[6]}></img>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"J'adore ! Je me bats avec des taches de café depuis des années, or maintenant je peux enfin sourire à nouveau en toute confiance ! Et ça prend si peu de temps ! Plus besoin de dentiste non plus ! Je la recommande vivement, elle est incroyable !"</p>
                                <p className='blog-review-user'>- Julien F. de Caen</p>
                            </div>

                            <img className="full-img-norescale" src={item.imageArr[7]}></img>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"C'était la première fois que j'achetais quelque chose comme ça et jusqu'à présent, je m'étais toujours senti fidèle à certaines marques bien connues qui sont aussi plus chères. La différence de prix et les bonnes critiques de produits par rapport à d'autres produits similaires m'ont décidé à enfin l'essayer. Après plusieurs semaines d'utilisation, je dois dire que j'en suis extrêmement satisfait. Très bon achat, je le recommande vivement !"</p>
                                <p className='blog-review-user'>- Pierre C. de Strasbourg</p>
                            </div>

                            <p className='blog-title'>Conclusion : Devriez-vous Acquérir une TeethCare ?</p>

                            <div className='blog-rating-container new-element'>
                                <img className="blog-img-rating new-margin" src={rating}></img>
                                <p className='blog-detail-text'>Honnêtement, il n’y a même pas de débat à avoir. Vous voulez aveugler vos amis avec des dents incroyablement propres ? Vous voulez dormir pendant ces 5 minutes supplémentaires le matin ? Vous voulez en plus jamais avoir besoin d’aller chez le dentiste ?

                                    <b> Alors oui. À 100%.</b> Et cela même si elle n’était pas en promo. Saisissez votre <strong style={{ color: 'orange' }}>TeethCare</strong> avant qu’elle ne soit en rupture de stock.</p>

                            </div>

                            <p className='blog-title'>Où se procurer maintenant TeethCare ?</p>

                            <p className='blog-detail-text'>Vous pouvez le trouver directement sur le <a href={`/commande/${item.id}`}>site officiel TeethCare, en cliquant ici.</a></p>

                            <p className='blog-detail-text'>PS : En plus, <strong style={{ color: 'orange' }}>TeethCare</strong> est protégé par une <strong style={{ color: 'green' }}>GARANTIE satisfaite ou remboursée de 30 jours</strong>. Il vous suffit simplement de leur envoyer un mail et vous serez remboursé.</p>






                            <button className='blog-button-clickhere new-element' onClick={() => { navigate("/commande/" + item.id) }}>JE VEUX PROFITER DE LA RÉDUCTION DE -50% MAINTENANT {'>>'}</button>



                            {item.reviews &&

                                <>
                                    <p className='blog-title'>Ils utilisent déjà {item.name}, voici ce qu'ils disent</p>
                                    {item.reviews.map((el, index) => {

                                        return (
                                            <div key={index} className='blog-review new-element'>
                                                <p className='blog-review-text'>"{el.review}"</p>
                                                <p className='blog-review-user'>- {el.userName}, {el.userAge} ans</p>
                                            </div>
                                        )
                                    })}
                                </>
                            }



                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"J'ai des gencives très sensibles et je ne peux tout simplement pas supporter le fil dentaire ou les brosses à dents avec des fibres résistantes ... Cet appareil est incroyable, je le mets en mode dents sensibles et c'est comme un massage doux pour ma bouche, et je peux enfin nettoyer mes dents correctement. Mon dentiste m'a même complimenté quand j’y suis allée pour mon examen habituel. Je ne lui ai pas dit que je passais juste une minute par jour à nettoyer mes dents haha."</p>
                                <p className='blog-review-user'>- Catherine M. de Aubervilliers</p>
                            </div>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"Je suis ravie de l'achat de ce produit ! Il répond à toutes les attentes, de plus l'expédition est rapide et il est livré dans une boîte bien protégée. Je regardais les critiques d'autres marques et aucune d'entre elles ne m'a pleinement convaincu. Je suis finalement tombé sur cette marque et ce produit et j'ai vu que presque tout était positif."</p>
                                <p className='blog-review-user'>- Claudine S. de Bourges</p>
                            </div>

                            <div className='blog-review new-element'>
                                <p className='blog-review-text'>"Excellente qualité pour le prix et livraison rapide. Le site internet offre de nombreux avantages pour les personnes âgées... Bons prix, et la commande est arrivée le jour promis. J'ai acheté deux unités car c'était le meilleur rapport qualité/prix parmi toutes mes options et je reviendrai certainement pour plus."</p>
                                <p className='blog-review-user'>- Christianne T. de Rennes</p>
                            </div>

                            <button className='blog-button-clickhere' onClick={() => { navigate("/commande/" + item.id) }}>JE VEUX PROFITER DE LA RÉDUCTION DE -50% MAINTENANT {'>>'}</button>

                            <p className='blog-detail-text' style={{ display: 'flex', textAlign: 'center' }}><b>Commandez votre TeethCare maintenant avant qu'ils ne soient encore en rupture de stock</b></p>
                        </section>
                        <section className='sticky-item-container'>
                            <div className='sticky-item' onClick={() => { navigate("/commande/" + item.id) }}>
                                <div className='sticky-title new-element'>
                                    {item.name}
                                </div>
                                {item.description &&
                                    <div className='sticky-subtitle new-element'>
                                        {item.description}
                                    </div>
                                }
                                <img className="full-img-norescale new-element" src={item.image}></img>
                                <span className='sticky-text'>
                                    Cliquez ici pour vérifier la disponibilité et le prix {'>>'}
                                </span>
                            </div>
                        </section>
                    </article>

                </main>
            }


            <footer className='footer'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingInline: 10 }}>
                    <img className="footer-img" src={logo}></img>
                    <nav className='footer-nav'>
                        <span>
                            <a style={{ color: "white", textDecoration: 'none' }} href='/mentions-legales'>Mentions légales - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/politique-de-confidentialite'>Politique de confidentialité - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/contact'>Contact</a>
                        </span>

                    </nav>
                </div>


                <div style={{ maxWidth: 400, paddingInline: 10 }}>
                    <p style={{ color: 'white' }}>À propos</p>
                    <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ceci est un article sponsorisé et non un véritable article de news, ou blog. Cette histoire est basée sur les résultats que certaines personnes ont accomplis en utilisant ce produit. Les résultats peuvent varier en fonction des personnes et il se peut que vous n’atteignez pas le même résultat que sur les photos ou vidéos. Cette page peut recevoir une compensation pour chaque clic ou vente réalisée via ce site.</p>
                </div>
                {(!item?.isNotHealth || item?.isNotHealth == false) &&
                    <div style={{ maxWidth: 400, paddingInline: 10 }}>
                        <p style={{ color: 'white' }}>Clause de non-responsabilité en matière de Santé</p>
                        <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ce site Web n'est pas destiné à fournir des conseils médicaux ou à se substituer aux conseils et traitements médicaux de votre médecin personnel. Les visiteurs sont invités à consulter leur propre médecin ou tout autre professionnel de la santé qualifié pour le traitement de leurs problèmes médicaux. L'auteur ne peut être tenu pour responsable d'une mauvaise compréhension ou d'une mauvaise utilisation des informations contenues sur ce site, ni d'une perte, d'un dommage ou d’une blessure causée, ou prétendument causée, directement ou indirectement par un traitement, une action ou une application d'un aliment ou d'une source alimentaire dont il est question sur ce site. Les informations ne sont pas destinées à diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.</p>

                    </div>
                }











            </footer>
        </>
    )
}

export default TeethCare