// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import {getFunctions} from 'firebase/functions'
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "blogbienetre-9388e.firebaseapp.com",
  projectId: "blogbienetre-9388e",
  storageBucket: "blogbienetre-9388e.appspot.com",
  messagingSenderId: "323510158142",
  appId: "1:323510158142:web:a2e0629186655a7a66e26f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const functions = getFunctions(app, "europe-west1")
export const db = getFirestore(app)