import React, {useEffect, useState} from 'react'

export const getParams = (location, string) => {
    const params = new URLSearchParams(location.search);
    return params.get(string); 
}

export const roundedValueString = (value) => {
    return Number(Math.round((Number(value) + Number.EPSILON) * 100) / 100).toFixed(2).replace(".", ",")
  }

export const CountDownTimer = (minutes, seconds) => {
    const [minutesCountdown, setMinutesCountdown] = useState(minutes)

    const [secondsCountdown, setSecondsCountdown] = useState(seconds)

    useEffect(() => {
      

        const timer = setInterval(() => {
          
            var minutesCountdownBuf = minutesCountdown;
            var secondsCountdownBuf = secondsCountdown;

           
            
            secondsCountdownBuf--
            
            if(secondsCountdownBuf < 0) {
                secondsCountdownBuf = 59
                minutesCountdownBuf --
            }

            setMinutesCountdown(minutesCountdownBuf)
            setSecondsCountdown(secondsCountdownBuf)

        }, 1000);

        return () => clearInterval(timer);
    }, [secondsCountdown])

    return [ minutesCountdown, secondsCountdown]

}