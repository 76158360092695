import { createContext, useState, useEffect } from "react";

import {

} from "firebase/auth"

import {

    doc,
    getDoc,
    getDocs,
    deleteDoc,
    query,
    where,
    setDoc,
    collection

} from "firebase/firestore"


import { auth, db, functions } from "../firebase-config"

import {
    httpsCallable
} from "firebase/functions"


import { useNavigate, useLocation } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core'

import { faCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

import { faCircle, faCircleDot } from '@fortawesome/free-regular-svg-icons'


library.add(faCheck, faCircleCheck, faCircle, faCircleDot)



export const UserContext = createContext()

export function UserContextProvider(props) {

    let navigate = useNavigate();
    let location = useLocation();

    const [loadingData, setLoadingData] = useState(false);

    const [itemLoading, setItemLoading] = useState(true);

    const [item, setItem] = useState({});

    useEffect(() => {



    }, [])

    const getItemData = async (id) => {
        setItemLoading(true)
        const item = await getDoc(doc(db, "items", id)).then(async (doc) => {
            return { ...doc.data(), id: doc.id }
        }).catch(err => err)

        setItem(item)
      
        setItemLoading(false)

       
 

        return item
    }

    const makePayment = async (paymentMethodId, itemId, quantity, name, surName, email, phoneNumber, address, city, postalCode, country) => {
        const makePayment = httpsCallable(functions, 'makePayment');


        return await makePayment(
            {
                paymentMethodId: paymentMethodId,
                itemId: itemId,
                quantity: quantity,
                name: name,
                surName: surName,
                email: email,
                phoneNumber: phoneNumber,
                address: address,
                city: city,
                postalCode: postalCode,
                country: country
            }
        ).then(result => {
            console.log(result);
            return result
        }
        ).catch((error) => { console.log(error); return error })

    }

    const confirmPayment = async (paymentIntentId, itemId, quantity, name, surName, email, phoneNumber, address, city, postalCode, country) => {
        const makePayment = httpsCallable(functions, 'makePayment');

        return await makePayment(
            {
                paymentIntentId: paymentIntentId,
                itemId: itemId,
                quantity: quantity,
                name: name,
                surName: surName,
                email: email,
                phoneNumber: phoneNumber,
                address: address,
                city: city,
                postalCode: postalCode,
                country: country


            }
        ).then(result => {
            console.log(result);
            return result
        }
        ).catch((error) => { console.log(error); return error })

    }








    return (
        <UserContext.Provider value={{ item, itemLoading, getItemData, makePayment, confirmPayment }}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}