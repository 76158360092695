import React, { useEffect, useState, useRef, useContext } from 'react';

import { getParams } from './Function';

import { useNavigate, useLocation } from "react-router-dom";

import { UserContext } from "./context/userContext";

import logo from './assets/logo.png'

import valerie from './assets/valerie.png'
import { Helmet } from 'react-helmet';

const PIXEL_ID = 1649310;


function Home() {

    let navigate = useNavigate();

    let location = useLocation();



    const { item, itemLoading, getItemData } = useContext(UserContext)

    useEffect(() => {
        getData()
        async function getData() {
            const itemId = getParams(location, "id")
            const itemBuf = await getItemData(itemId)

            document.title = `${itemBuf.name} - Article révolutionnaire`
        }

    }, [])






    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {` window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: ${PIXEL_ID}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/${PIXEL_ID}/tfa.js',
    'tb_tfa_script');`}
                </script>
                <script>
                    {` function taboolaPaymentDone(revenue, quantity) {
        _tfa.push({ notify: 'event', name: 'make_purchase', id:${PIXEL_ID}, 
         revenue, quantity, 
        currency: '€' })
      }`}
                </script>
            </Helmet>

            <header className='header new-element' style={{ height: 170, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='new-element' style={{ width: '100%', display: 'flex', background: 'rgb(20, 20, 20)', justifyContent: 'center' }}>
                    <span style={{ color: 'lightgray', fontSize: '1em' }}>Advertorial</span>
                </div>
                <img className="header-img" src={logo}></img>
            </header>
            {itemLoading == false && item &&
                <main className='container'>



                    <article className='container'>

                        <section className="home-blog-detail new-element">
                            <div className='avatar-name-container'>
                                <img className="avatar-img" src={valerie}></img>
                                <span style={{ fontStyle: 'italic' }}>Par Mélanie Faure, rédactrice Vita Bien-Être depuis 2016, {item.date || "le 3 octobre 2022"}</span>
                            </div>
                            <h1 className='home-title new-element'>{item.title}</h1>
                            <p className='home-subtitle new-element' >{item.subTitle}</p>
                            <div className='new-element'>
                                <img className="full-img" src={item.presentationImage}></img>
                                <span className='img-detail'>{item.presentationImageDetail}</span>
                            </div>

                            {item.resumeText &&
                                <div className='home-resume new-element'>
                                    <span className='blog-detail-text' dangerouslySetInnerHTML={{ __html: item.resumeText }} />
                                </div>
                            }

                            <p className='blog-description-detail ' >{item.presentationDescriptionTitle}</p>

                            <p className='blog-description-subdetail' >{item.presentationDescriptionSubTitle}</p>

                            {item.presentationDescriptionSubtitles && item.presentationDescriptionSubtitles.map((el, index) => {

                                return (
                                    <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-description-subdetail'></p>
                                )
                            })}

                            <p className='blog-title '>{item.whatIsTitle}</p>
                            <img className="blog-product-center-img" src={item.image}></img>

                            {item.whatIsText && item.whatIsText.map((el, index) => {

                                return (
                                    <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-detail-text'></p>
                                )
                            })}


                            {item.stepImage &&
                                <>

                                    <p className='blog-title'>Comment fonctionne-t-il ?</p>

                                    <img className="full-img-norescale" src={item.stepImage}></img>

                                    <p className='blog-detail-text'><b>Il est très facile à mettre en place :</b></p>

                                    {item.stepText && item.stepText.map((el, index) => {

                                        return (
                                            <p key={index} dangerouslySetInnerHTML={{ __html: `${index + 1} - ${el}` }} className='blog-detail-text'></p>
                                        )
                                    })}

                                    {item.howItWorksText && item.howItWorksText.map((el, index) => {

                                        return (
                                            <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-detail-text'></p>
                                        )
                                    })}

                                    {item.whatItDoes &&
                                        <>
                                            <p className='blog-title'>Que fait exactement l’appareil {item.name} ?</p>

                                            <img className="full-img-norescale" src={item.whatItDoesImage}></img>


                                            {item.whatItDoes.map((el, index) => {

                                                return (
                                                    <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-detail-text'></p>
                                                )
                                            })}

                                        </>}
                                </>
                            }

                            {item.whatIsPriceText
                                &&
                                <>
                                    <p className='blog-title'>Quel est son prix ?</p>

                                    {item.whatIsPriceText && item.whatIsPriceText.map((el, index) => {

                                        return (
                                            <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-detail-text'></p>
                                        )
                                    })}


                                    <img className="full-img-norescale new-element" src={item.tableComparisonImage}></img>
                                </>
                            }

                            <button className='blog-button-clickhere' onClick={() => {
                                navigate("/commande/" + item.id, {
                                    state: { PIXEL_ID: PIXEL_ID },
                                });
                            }}>Cliquez ici pour vérifier la disponibilité de {item.name} {'>>'}</button>

                            <p className='blog-title'>Ils utilisent déjà {item.name}, voici ce qu'ils disent</p>

                            {item.reviews && item.reviews.map((el, index) => {

                                return (
                                    <div key={index} className='blog-review new-element'>
                                        <p className='blog-review-text'>"{el.review}"</p>
                                        <p className='blog-review-user'>- {el.userName}, {el.userAge} ans</p>
                                    </div>
                                )
                            })}

                            {item.conclusionText &&
                                <>
                                    <p className='blog-title'>Conclusion : Est-ce une bonne idée d’utiliser l’appareil {item.name} ?</p>



                                    {item.conclusionText.map((el, index) => {

                                        return (
                                            <p key={index} dangerouslySetInnerHTML={{ __html: el }} className='blog-detail-text'></p>
                                        )
                                    })}

                                </>}


                            <p className='blog-title'>Comment faire pour obtenir un {item.name}</p>

                            <p className='blog-detail-text'><b>FAITES ATTENTION aux contrefaçons, car ils n'utilisent pas les mêmes normes de sécurité que l’appareil {item.name} original et risquent fortement de ne pas fonctionner.</b></p>

                            <p className='blog-detail-text'><b>{item.name} est livré avec une garantie de remboursement valable 30 jours,</b> vous ne courez donc aucun risque en l'essayant ! Si vous agissez maintenant, vous pouvez encore bénéficier de leur remise exceptionnelle de 50% valable uniquement jusqu’à la fin de semaine. N'attendez donc pas pour pouvoir profiter de {item.name} à prix cassé !</p>

                            <button className='blog-button-clickhere' onClick={() => { navigate("/commande/" + item.id) }}>Cliquez ici pour vérifier la disponibilité de {item.name} {'>>'}</button>

                            <p className='blog-detail-text' style={{ display: 'flex', textAlign: 'center' }}><b>Commandez le vôtre maintenant avant qu'ils ne soient encore en rupture de stock</b></p>
                        </section>
                        <section className='sticky-item-container'>
                            <div className='sticky-item' onClick={() => { navigate("/commande/" + item.id) }}>
                                <div className='sticky-title new-element'>
                                    {item.name}
                                </div>
                                {item.description &&
                                    <div className='sticky-subtitle new-element'>
                                        {item.description}
                                    </div>
                                }
                                <img className="full-img-norescale new-element" src={item.image}></img>
                                <span className='sticky-text'>
                                    Cliquez ici pour vérifier la disponibilité et le prix {'>>'}
                                </span>
                            </div>
                        </section>
                    </article>

                </main>
            }


            <footer className='footer'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingInline: 10 }}>
                    <img className="footer-img" src={logo}></img>
                    <nav className='footer-nav'>
                        <span>
                            <a style={{ color: "white", textDecoration: 'none' }} href='/mentions-legales'>Mentions légales - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/politique-de-confidentialite'>Politique de confidentialité - </a>

                            <a style={{ color: "white", textDecoration: 'none' }} href='/contact'>Contact</a>
                        </span>

                    </nav>
                </div>


                <div style={{ maxWidth: 400, paddingInline: 10 }}>
                    <p style={{ color: 'white' }}>À propos</p>
                    <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ceci est un article sponsorisé et non un véritable article de news, ou blog. Cette histoire est basée sur les résultats que certaines personnes ont accomplis en utilisant ce produit. Les résultats peuvent varier en fonction des personnes et il se peut que vous n’atteignez pas le même résultat que sur les photos ou vidéos. Cette page peut recevoir une compensation pour chaque clic ou vente réalisée via ce site.</p>
                </div>
                {(!item?.isNotHealth || item?.isNotHealth == false) &&
                    <div style={{ maxWidth: 400, paddingInline: 10 }}>
                        <p style={{ color: 'white' }}>Clause de non-responsabilité en matière de Santé</p>
                        <p style={{ fontSize: '0.8em', color: 'lightgray' }} >Ce site Web n'est pas destiné à fournir des conseils médicaux ou à se substituer aux conseils et traitements médicaux de votre médecin personnel. Les visiteurs sont invités à consulter leur propre médecin ou tout autre professionnel de la santé qualifié pour le traitement de leurs problèmes médicaux. L'auteur ne peut être tenu pour responsable d'une mauvaise compréhension ou d'une mauvaise utilisation des informations contenues sur ce site, ni d'une perte, d'un dommage ou d’une blessure causée, ou prétendument causée, directement ou indirectement par un traitement, une action ou une application d'un aliment ou d'une source alimentaire dont il est question sur ce site. Les informations ne sont pas destinées à diagnostiquer, traiter, guérir ou prévenir une quelconque maladie.</p>

                    </div>
                }











            </footer>
        </>
    )
}

export default Home